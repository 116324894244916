import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { API } from "../../queries/api";
import { UserType } from "../../types";
import cn from "classnames";
import ps from "./css/admins.module.scss";
import DEFAULT_USER_IMG from "../../imgs/Default_user_img.png";
import { Button } from "@mui/material";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import useSnackbar from "../../hooks/useSnackbar";
import useSelfUser from "../../hooks/useSelfUser";

const Admins = () => {
	const [admins, setAdmins] = useState<UserType[]>([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [adminToRemove, setAdminToRemove] = useState<UserType>();
	const { clubId: adminClubId, isSuperAdmin } = useSelfUser();
	const openSnackbar = useSnackbar();

	const handleClickOpenModal = () => {
		setOpen(true);
	};

	const handleCloseModal = () => {
		setOpen(false);
	};

	useEffect(() => {
		fetchAdmins();
	}, []);

	const fetchAdmins = async () => {
		setLoading(true);
		try {
			const url = isSuperAdmin ? "admins" : `admins/moderators?idClub=${adminClubId}`;
			const { data, status } = await API.get(url);
			if (status === 200 && !!data) {
				setAdmins(data);
			}
		} catch (error) {
			console.error("Error fetching admins:", error);
		}
		setLoading(false);
	};

	const removeAdmin = async () => {
		try {
			const id = adminToRemove?.id;
			const url = isSuperAdmin ? `admin/${id}` : `admin/${id}/moderator`;
			const { status } = await API.delete(url);
			if (status === 200) {
				setAdmins(admins.filter((admin) => admin.id !== id));
				handleCloseModal();
				openSnackbar("Admin removed successfully");
			}
		} catch (error) {
			openSnackbar("Error removing admin");
			console.error("Error removing admin:", error);
		}
	};

	return (
		<Paper
			sx={{
				p: 2,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography component="h2" variant="h6" color="primary" gutterBottom>
				Admins
			</Typography>
			{loading && (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			)}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Icon</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Club</TableCell>
							<TableCell>Role</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{!!admins.length ? (
							admins.map((row) => (
								<TableRow key={row.id} sx={{ cursor: "pointer" }}>
									<TableCell>
										<img
											className={cn(ps.avatar)}
											src={row.imageLink || DEFAULT_USER_IMG}
											alt={row.firstName}
											onError={(ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
												(ev.target as HTMLImageElement).src = DEFAULT_USER_IMG;
											}}
										/>
									</TableCell>
									<TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
									<TableCell>{row.email}</TableCell>
									<TableCell>{row.club?.name || ""}</TableCell>
									<TableCell>{row.role}</TableCell>
									<TableCell>
										<Button
											variant="contained"
											color="error"
											onClick={() => {
												setAdminToRemove(row);
												handleClickOpenModal();
											}}
											size="small"
										>
											Remove
										</Button>
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={12} align="center">
									No admins found
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Dialog open={open} onClose={handleCloseModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{`Are you sure you want to delete admin ${adminToRemove?.firstName} ${adminToRemove?.lastName}?`}</DialogTitle>
				<DialogActions>
					<Button onClick={handleCloseModal} variant="outlined">
						Cancel
					</Button>
					<Button onClick={removeAdmin} variant="contained" color="error">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</Paper>
	);
};

export default Admins;
