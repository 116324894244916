import axios from "axios";

const SERVER_KEY = `ServerKey ${process.env.REACT_APP_SERVER_KEY}`;
const API_HOST = process.env.REACT_APP_API;
const API_VER = "/api/v1";
const API_BASE_LINK = `${API_HOST}${API_VER}`;

function getAtoken() {
	let aToken = "Bearer " + JSON.parse(localStorage.getItem("aToken"));
	return aToken;
}

export function storeAToken(response) {
	try {
		localStorage.setItem("aToken", JSON.stringify(response.headers.authorization.split(" ")[1]));
	} catch (error) {
		// console.log(error);
	}
}

export function storeATokenD(token) {
	try {
		localStorage.setItem("aToken", JSON.stringify(token));
	} catch (error) {
		// console.log(error);
	}
}

let API = {
	post: async (path, payload = {}, config = { showDefaultError: false }) => {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: `${API_BASE_LINK}/${path}`,
			data: payload,
		}).catch((error) => {
			// handleError(error, config);
			console.log({ error });
			throw error;
		});
		storeAToken(response);
		return response;
	},

	put: async (path, payload = {}, config = { showDefaultError: false }) => {
		const response = await axios({
			method: "put",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: `${API_BASE_LINK}/${path}`,
			data: payload,
		}).catch((error) => {
			// handleError(error, config);
			console.log({ error });
			throw error;
		});
		storeAToken(response);
		return response;
	},

	patch: async (path, payload = {}, config = { showDefaultError: false }) => {
		const response = await axios({
			method: "patch",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: `${API_BASE_LINK}/${path}`,
			data: payload,
		}).catch((error) => {
			// handleError(error, config);
			console.log({ error });
			throw error;
		});
		storeAToken(response);
		return response;
	},

	postSK: async (path, payload = {}, config = { storeBearer: true }) => {
		const response = await axios({
			method: "post",
			headers: {
				// Authorization: SERVER_KEY,
				"x-api-key": SERVER_KEY,
				"Content-Type": "application/json",
			},
			url: `${API_BASE_LINK}/${path}`,
			data: payload,
		}).catch((error) => {
			// handleError(error);
			console.log({ error });
			throw error;
		});
		if (config.storeBearer) storeAToken(response);
		return response;
	},

	get: async (path) => {
		const response = await axios({
			method: "get",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: `${API_BASE_LINK}/${path}`,
		}).catch((error) => {
			// handleError(error);
			console.log({ error });
			throw error;
		});
		storeAToken(response);
		return response;
	},

	multipart: async (path, payload = {}, uploadPercent) => {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "multipart/form-data",
			},
			url: `${API_BASE_LINK}/${path}`,
			data: payload,
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				uploadPercent(percentCompleted);
			},
		}).catch((error) => {
			// handleError(error);
			throw error;
		});
		storeAToken(response);
		return response;
	},

	delete: async (path, payload = {}) => {
		const response = await axios({
			method: "delete",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "multipart/form-data",
			},
			url: `${API_BASE_LINK}/${path}`,
			data: payload,
		}).catch((error) => {
			// handleError(error);
			throw error;
		});
		return response;
	},
};

export { API };
