import React, { useState } from "react";
import "cropperjs/dist/cropper.css";
import { styled } from "@mui/material/styles";
import { API } from "../../queries/api";
import { Button, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LoadingButton from "@mui/lab/LoadingButton";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

interface ImageUploadProps {
	type: string;
	onUpload: (image: string) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ type, onUpload }) => {
	const [file, setFile] = useState<any>();
	const [loading, setLoading] = useState(false);

	const onChange = (e: any) => {
		e.preventDefault();
		setLoading(true);
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		setFile(files[0]);
		handleUpload(files[0]);
	};

	const handleUpload = async (file: any) => {
		try {
			const formData = new FormData();
			formData.append("file", file);
			const query = `file?type=${type}`;

			const response = await API.multipart(query, formData, (percent: string) => console.log(percent));
			if (response.status === 200 && response?.data?.mediaURL) {
				onUpload(response?.data?.mediaURL);
			}
		} catch (error) {
			console.error("Error uploading file", error);
		}
		setLoading(false);
	};

	return (
		<>
			<div style={{ display: "flex", justifyContent: "flex-start", padding: "16px 0", alignItems: "center" }}>
				{loading ? (
					<LoadingButton loading variant="outlined" disabled sx={{ width: "200px" }}>
						<span>disabled</span>
					</LoadingButton>
				) : (
					<Button component="label" variant="contained" color="success" startIcon={<CloudUploadIcon />} sx={{ ml: 2 }}>
						Upload club rules
						<VisuallyHiddenInput type="file" accept=".html" onChange={onChange} />
					</Button>
				)}
				{file?.name && (
					<Typography variant="body1" sx={{ ml: 1 }}>
						{file.name}
					</Typography>
				)}
			</div>
		</>
	);
};

export default ImageUpload;
