import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { API } from "../../queries/api";
import useSnackbar from "../../hooks/useSnackbar";
import { FolderType, LibraryType, LibraryItemType } from "../../types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { GENERAL_STATUSES } from "../../constants";
import { Stack } from "@mui/material";
import useConfirmModal from "../../hooks/useConfirmModal";
import useSelfUser from "../../hooks/useSelfUser";
import LibraryItem from "./LibraryItem";
import SelectFolderModal from "./SelectFolderModal";
import { SuggestionItem } from "./SuggestionItem";

const Library = () => {
	const [suggestions, setSuggestions] = useState<LibraryType[]>([]);
	const [folders, setFolders] = useState<FolderType[]>([]);
	const [loading, setLoading] = useState(false);
	const [libraryType, setLibraryType] = useState<string>(
		GENERAL_STATUSES.PENDING
	);
	const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
	const [selectedSuggestionId, setSelectedSuggestionId] = useState<
		string | null
	>(null);
	const [selectedFolderId, setSelectedFolderId] = useState<
		string | undefined
	>();
	const [folderItems, setFolderItems] = useState<LibraryItemType[]>([]);
	const openSnackbar = useSnackbar();
	const { clubId } = useSelfUser();
	const isPending = libraryType === GENERAL_STATUSES.PENDING;
	// const isRejected = libraryType === GENERAL_STATUSES.REJECTED;
	const isApproved = libraryType === GENERAL_STATUSES.APPROVED;

	const { openConfirmModal: openRejectConfirmModal } = useConfirmModal({
		onConfirm: ({ itemData }) => {
			rejectHandle(itemData.id);
		},
		title: "Are you sure you want to reject this suggestion?",
	});

	// const { openConfirmModal: openDeleteUserConfirmModal } = useConfirmModal({
	// 	onConfirm: ({ itemData }) => {
	// 		deleteUserHandle(itemData?.userDTO?.id);
	// 	},
	// });

	const handleChangeReqType = (event: SelectChangeEvent) => {
		setLibraryType(event.target.value as string);
	};

	const handleChangeFolderId = (event: SelectChangeEvent) => {
		setSelectedFolderId(event.target.value as string);
		fetchFolderItems(event.target.value as string);
	};

	const fetchFolderItems = async (folderId: string) => {
		setLoading(true);
		try {
			const { data, status } = await API.get(`library/items/${folderId}`);
			if (status === 200) {
				setFolderItems(data);
			} else {
				openSnackbar("Error fetching folder items");
			}
		} catch (error) {
			console.error("Error fetching folder items:", error);
			openSnackbar("Error fetching folder items");
		}
		setLoading(false);
	};

	const fetchSuggestions = async () => {
		setLoading(true);
		try {
			const { data, status } = await API.get(`library/suggestions/${clubId}`);
			if (status === 200) {
				setSuggestions(data);
			} else {
				openSnackbar("Error fetching requests");
			}
		} catch (error) {
			console.error("Error fetching requests:", error);
			openSnackbar("Error fetching requests");
		}
		setLoading(false);
	};

	const fetchFolders = async () => {
		setLoading(true);
		try {
			const { data, status } = await API.get(
				`library/folders?idClub=${clubId}`
			);
			if (status === 200) {
				setFolders(data);
			} else {
				openSnackbar("Error fetching folders");
			}
		} catch (error) {
			console.error("Error fetching folders:", error);
			openSnackbar("Error fetching folders");
		}
		setLoading(false);
	};

	const approveHandle = async (folderId: string) => {
		setLoading(true);
		try {
			const { status } = await API.post(
				`library/item/approve/${selectedSuggestionId}?idFolder=${folderId}`
			);
			if (status === 200) {
				openSnackbar("Suggestion approved successfully");
				fetchSuggestions();
			} else {
				openSnackbar("Error approving suggestion");
			}
		} catch (error) {
			console.error("Error approving suggestion:", error);
			openSnackbar("Error approving suggestion");
		}
		setLoading(false);
		setSelectedSuggestionId(null);
	};

	const rejectHandle = async (suggestionId: string) => {
		setLoading(true);
		try {
			const { status } = await API.post(`library/item/reject/${suggestionId}`);
			if (status === 200) {
				openSnackbar("Suggestion rejected successfully");
				fetchSuggestions();
			} else {
				openSnackbar("Error rejecting suggestion");
			}
		} catch (error) {
			console.error("Error rejecting suggestion:", error);
			openSnackbar("Error rejecting suggestion");
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchSuggestions();
		fetchFolders();
	}, []);

	return (
		<Paper
			sx={{
				p: 2,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography component="h2" variant="h6" color="primary" gutterBottom>
				Library
			</Typography>
			{loading && (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			)}
			<Stack spacing={3} direction="row">
				<FormControl sx={{ width: "200px", my: 1 }}>
					<InputLabel id="lib-type-label">Select library type</InputLabel>
					<Select
						labelId="lib-type-label"
						id="lib-type-label"
						value={libraryType}
						label="Select keyword type"
						onChange={handleChangeReqType}
					>
						{Object.keys(GENERAL_STATUSES)
							.filter((status) => status !== GENERAL_STATUSES.REJECTED)
							.map((key) => (
								<MenuItem key={key} value={key}>
									{GENERAL_STATUSES[key as keyof typeof GENERAL_STATUSES]}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				{isApproved && (
					<FormControl sx={{ width: "200px", my: 1 }}>
						<InputLabel id="folder-label">Select folder</InputLabel>
						<Select
							labelId="folder-label"
							id="folder-label"
							value={selectedFolderId}
							label="Select folder"
							onChange={handleChangeFolderId}
						>
							{folders.map((folder) => (
								<MenuItem key={folder.id} value={folder.id}>
									{folder.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
			</Stack>
			<br />
			<Stack
				spacing={2}
				direction="row"
				useFlexGap
				flexWrap="wrap"
				alignItems="flex-start"
			>
				{isPending &&
					suggestions &&
					suggestions.map((suggestion) => (
						<SuggestionItem
							key={suggestion.id}
							item={suggestion}
							onApprove={(suggestionId) => {
								setSelectedSuggestionId(suggestionId);
								setIsFolderModalOpen(true);
							}}
							onReject={() => {
								openRejectConfirmModal({ itemData: suggestion });
							}}
						/>
					))}
				{isApproved &&
					folderItems &&
					folderItems.map((item) => <LibraryItem key={item.id} item={item} />)}
			</Stack>
			<SelectFolderModal
				folders={folders}
				isOpen={isFolderModalOpen}
				onClose={() => setIsFolderModalOpen(false)}
				onConfirm={(folderId) => {
					setIsFolderModalOpen(false);
					approveHandle(folderId);
				}}
			/>
		</Paper>
	);
};

export default Library;
