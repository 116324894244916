import Button from "@mui/material/Button";
import { Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
import { FolderType } from "../../types";
import ps from "./css/selectFolderModal.module.scss";
import cn from "classnames";
import DialogActions from "@mui/material/DialogActions";
import Avatar from "@mui/material/Avatar";
import { mapColors } from "../../utils/mapColors";
import { getFolderIcon } from "./folderIcon.util";

interface AddUsersByInterestsModalProps {
	isOpen: boolean;
	folders: FolderType[];
	onClose: () => void;
	onConfirm: (id: string) => void;
}

export default function SelectFolderModal({
	isOpen,
	folders,
	onClose,
	onConfirm,
}: AddUsersByInterestsModalProps) {
	const handleCloseModal = () => {
		onClose();
	};

	const selectFolderHandle = (id: string) => {
		onConfirm(id);
	};

	return (
		<Dialog open={isOpen} onClose={handleCloseModal} fullWidth>
			<DialogTitle id="alert-dialog-title">
				Select a folder to store the library item:
			</DialogTitle>
			<DialogContent>
				<div className={cn(ps["search-table"])}>
					{!!folders.length &&
						folders.map((folder) => {
							return (
								<div key={folder.id} className={cn(ps["table-category-item"])}>
									<div className={cn(ps["table-item"])}>
										<Avatar
											src={getFolderIcon(folder.image)}
											sx={{
												bgcolor: mapColors(folder.background),
											}}
											aria-label="folder"
											className={cn(ps["folder-icon"])}
										/>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												marginLeft: "8px",
											}}
										>
											<Typography variant="h5">{folder.name}</Typography>
											<Typography variant="body2" color="text.secondary">
												Number of items: {folder.numberOfItems}
											</Typography>
										</div>
										<Button
											size="small"
											onClick={() => selectFolderHandle(folder.id)}
											sx={{ marginLeft: "auto" }}
										>
											Select
										</Button>
									</div>
								</div>
							);
						})}
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseModal} variant="outlined">
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
