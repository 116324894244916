import React, { useEffect, useState } from "react";
import { API } from "../../queries/api";
import { useNavigate, useParams } from "react-router-dom";
import {
	Autocomplete,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Grid,
	TextField,
} from "@mui/material";
import { Form, Formik, useFormik, useFormikContext } from "formik";
import { Avatar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import dayjs, { Dayjs } from "dayjs";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { UserType } from "../../types";
import useSnackbar from "../../hooks/useSnackbar";
import Companies from "./Companies";
import { IMAGE_TYPES, KEYWORD_TYPES } from "../../constants";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ImageUpload from "../../components/image-upload/ImageUpload";
import ps from "./css/user.module.scss";

const UserForm = () => {
	const { values, handleChange, setFieldValue, handleSubmit } =
		useFormikContext<any>();
	const {
		email,
		firstName,
		lastName,
		aboutMe,
		imageLink,
		phoneNumber,
		status,
		username,
		dateOfBirth,
		assistantsPhoneNumber,
		residence,
		interests,
		companies,
		idClub,
	} = values;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);
	const [dateOfBirthLocal, setDateOfBirthLocal] = useState<Dayjs | null>(
		dayjs(dateOfBirth)
	);
	const [allInterests, setAllInterests] = useState<any[]>([]);
	const navigate = useNavigate();
	const openSnackbar = useSnackbar();

	useEffect(() => {
		getInterests();
	}, []);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleCloseDeleteModal = () => {
		setIsDeleteUserModalOpen(false);
	};
	const handleOpenDeleteModal = () => {
		setIsDeleteUserModalOpen(true);
	};

	const getInterests = async () => {
		try {
			const { data, status } = await API.get(
				`keyword/${KEYWORD_TYPES.PERSONAL_INTERESTS}?idClub=${idClub}`
			);
			if (status === 200 && !!data.length) {
				setAllInterests(data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleDeleteUser = async () => {
		try {
			const { status } = await API.delete(`user/${values.id}`);
			if (status === 200) {
				setIsDeleteUserModalOpen(false);
				openSnackbar("User deleted successfully");
				navigate(-1);
			}
		} catch (error) {
			console.log(error);
			openSnackbar("Error deleting user");
		}
	};

	const handlePromoteToAdmin = async () => {
		try {
			const { status } = await API.patch(`admin/${values.id}`);
			if (status === 200) {
				openSnackbar("User promoted to admin successfully");
				navigate("/admins");
			}
		} catch (error) {
			console.log(error);
			openSnackbar("Error promoting user to admin");
		}
	};

	const handlePromoteToModerator = async () => {
		try {
			const { status } = await API.patch(`admin/${values.id}/moderator`);
			if (status === 200) {
				openSnackbar("User promoted to admin successfully");
				navigate("/admins");
			}
		} catch (error) {
			console.log(error);
			openSnackbar("Error promoting user to admin");
		}
	};

	const newCompanyHandle = async (newCompany: any) => {
		await setFieldValue("companies", [...companies, newCompany]);
		// Hack to wait for new value to be applied
		// Pending https://github.com/jaredpalmer/formik/issues/529
		setTimeout(() => handleSubmit(), 1000);
		// handleSubmit();
	};

	if (!status) return null;

	return (
		<>
			<Card variant="outlined">
				<CardContent>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<ImageUpload
							onUpload={(url) => {
								setFieldValue("imageLink", url);
							}}
							type={IMAGE_TYPES.PROFILE_PHOTO}
						>
							<Avatar
								alt={lastName}
								src={imageLink}
								sx={{ width: 100, height: 100 }}
								className={ps["upload-avatar"]}
							/>
						</ImageUpload>
						<div>
							<IconButton
								aria-label="more"
								id="long-button"
								aria-controls={openMenu ? "long-menu" : undefined}
								aria-expanded={openMenu ? "true" : undefined}
								aria-haspopup="true"
								onClick={handleClick}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="long-menu"
								MenuListProps={{
									"aria-labelledby": "long-button",
								}}
								anchorEl={anchorEl}
								open={openMenu}
								onClose={handleClose}
							>
								<MenuItem onClick={handlePromoteToAdmin}>
									Promote to admin
								</MenuItem>
								<MenuItem onClick={handlePromoteToModerator}>
									Promote to moderator
								</MenuItem>
								<MenuItem onClick={handleOpenDeleteModal}>Delete User</MenuItem>
							</Menu>
						</div>
					</div>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<TextField
								name="firstName"
								label="First Name"
								fullWidth
								variant="outlined"
								value={firstName}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name="lastName"
								label="Last Name"
								fullWidth
								variant="outlined"
								value={lastName}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name="email"
								label="Email"
								fullWidth
								variant="outlined"
								value={email}
								onChange={handleChange}
								disabled
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name="status"
								label="Status"
								fullWidth
								variant="outlined"
								value={status}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name="username"
								label="Username"
								fullWidth
								variant="outlined"
								value={username}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name="phoneNumber"
								label="Phone Number"
								fullWidth
								variant="outlined"
								value={phoneNumber}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name="aboutMe"
								label="About Me"
								fullWidth
								variant="outlined"
								value={aboutMe}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name="residence"
								label="Residence"
								fullWidth
								variant="outlined"
								value={residence}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={6}>
							<DatePicker
								format="YYYY/MM/DD"
								label="Date Of Birth"
								value={dateOfBirthLocal}
								onChange={(newValue) => {
									setDateOfBirthLocal(newValue);
									setFieldValue("dateOfBirth", newValue, true);
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								name="assistantsPhoneNumber"
								label="Assistants Phone Number"
								fullWidth
								variant="outlined"
								value={assistantsPhoneNumber}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								multiple
								options={allInterests || []}
								getOptionLabel={(option) => option?.word}
								defaultValue={interests}
								filterSelectedOptions
								onChange={(e, value) => setFieldValue("interests", value)}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Interests"
										placeholder="Interests"
									/>
								)}
							/>
						</Grid>
					</Grid>

					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							paddingTop: "16px",
						}}
					>
						<Button type="submit" variant="contained" color="primary">
							Update user
						</Button>
					</div>
					<Dialog
						open={isDeleteUserModalOpen}
						onClose={handleCloseDeleteModal}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">
							Are you sure you want to delete this user?
						</DialogTitle>
						<DialogActions>
							<Button onClick={handleCloseDeleteModal} variant="outlined">
								Cancel
							</Button>
							<Button
								onClick={handleDeleteUser}
								variant="contained"
								color="error"
							>
								Delete
							</Button>
						</DialogActions>
					</Dialog>
				</CardContent>
			</Card>
			<Companies
				companies={companies}
				idClub={idClub}
				onNewCompanyCreated={newCompanyHandle}
			/>
		</>
	);
};

export default function User() {
	const [user, setUser] = useState<UserType | {}>({
		email: "",
		dateOfBirth: null,
	});
	const [loading, setLoading] = useState(false);
	const { userId } = useParams<{ userId: string }>();
	const openSnackbar = useSnackbar();

	useEffect(() => {
		getUser();
	}, []);

	const getUser = async () => {
		try {
			setLoading(true);
			const { data, status } = await API.get(`user/${userId}`);
			if (status === 200 && data?.id) {
				setUser(data);
				formik.setValues(data);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	const handleSubmit = async (values: any) => {
		try {
			const { status } = await API.put("user", values);
			if (status === 200) {
				openSnackbar("User updated successfully");
			}
		} catch (error) {
			console.log(error);
			openSnackbar("Error updating user");
		}
	};

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		onSubmit: (values) => {},
	});

	if (loading) return <CircularProgress />;
	return (
		<Formik initialValues={user} enableReinitialize onSubmit={handleSubmit}>
			<Form>
				<UserForm />
			</Form>
		</Formik>
	);
}
