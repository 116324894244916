import { ChannelList } from "stream-chat-react";

import { ChannelSearch } from "../ChannelSearch/ChannelSearch";
import { TeamChannelList } from "../TeamChannelList/TeamChannelList";
import { ChannelPreview } from "../ChannelPreview/ChannelPreview";

import { CompanyLogo } from "./icons";

import type { Channel } from "stream-chat";
import { ChannelSort } from "stream-chat";

import { StreamChatType } from "../../types";

type SidebarProps = {
	chatId: string;
};

const options = { state: true, watch: true, presence: true, limit: 10 };
const sort: ChannelSort<StreamChatType> = { last_message_at: -1, updated_at: -1 };

const FakeCompanySelectionBar = () => (
	<div className="sidebar__company-selection-bar">
		<div className="sidebar__company-badge">
			<CompanyLogo />
		</div>
	</div>
);

const customChannelTeamFilter = (channels: Channel[]) => {
	return channels.filter((channel) => channel.type === "team");
};

const TeamChannelsList = ({ chatId }: SidebarProps) => {
	const filter = {
		id: {
			$in: [chatId],
		},
	};

	return (
		<ChannelList
			channelRenderFilterFn={customChannelTeamFilter}
			filters={filter}
			options={options}
			sort={sort}
			List={(listProps) => <TeamChannelList {...listProps} type="team" />}
			Preview={(previewProps) => <ChannelPreview {...previewProps} type="team" />}
		/>
	);
};

export const Sidebar = ({ chatId }: SidebarProps) => {
	return (
		<div className="sidebar">
			<FakeCompanySelectionBar />
			<div className="channel-list-bar">
				<div className="channel-list-bar__header">
					<p className="channel-list-bar__header__text">Worksly</p>
				</div>
				<ChannelSearch />
				<TeamChannelsList chatId={chatId} />
			</div>
		</div>
	);
};
