import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { API } from "../../queries/api";
import BACKGROUND_IMAGE from "../../imgs/BRIDGES_SIGNIN_BACKGROUND.png";
import { ADMIN_ROLES, ADMIN_ROLE } from "../../constants";

export default function SignInSide() {
	const navigate = useNavigate();
	const [error, setError] = useState("");

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const form = new FormData(event.currentTarget);
		try {
			const data = {
				email: form.get("email"),
				password: form.get("password"),
			};
			const response = await API.postSK(`login`, data);
			if (response.status === 200 && ADMIN_ROLES.includes(response.data.role ?? "") && response.data.status === "ACTIVE") {
				if (response.data.role === ADMIN_ROLE.ADMIN) {
					navigate("/clubs");
				} else {
					navigate("/club");
				}
			} else {
				setError("Invalid email or password");
			}
		} catch (e) {
			setError("Invalid email or password");
			return console.error(e);
		}
	};

	return (
		<Grid container component="main" sx={{ height: "100vh" }}>
			<CssBaseline />
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{
					backgroundImage: `url(${BACKGROUND_IMAGE})`,
					backgroundRepeat: "no-repeat",
					backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			/>
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				component={Paper}
				elevation={6}
				square
				sx={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						my: 4,
						mx: 4,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Sign in
					</Typography>
					<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
						<TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
						<TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
						<Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
							Sign In
						</Button>
					</Box>
					{error && (
						<Typography variant="body2" color="error">
							{error}
						</Typography>
					)}
				</Box>
			</Grid>
		</Grid>
	);
}
