import PeopleIcon from "@mui/icons-material/People";
import BusinessIcon from "@mui/icons-material/Business";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BallotIcon from "@mui/icons-material/Ballot";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import { SideNavigationItem } from "../types";

export const sideNavigationItemsClubModerator: Array<SideNavigationItem> = [
	{
		name: "Users",
		icon: <PeopleIcon />,
		link: "/search-user",
	},
	{
		name: "Club",
		icon: <BusinessIcon />,
		link: "/club",
	},
	{
		name: "Keywords",
		icon: <BallotIcon />,
		link: "/keywords",
	},
	{
		name: "Market Requests",
		icon: <AssignmentReturnedIcon />,
		link: "/requests",
	},
	{
		name: "Registration Requests",
		icon: <HowToRegIcon />,
		link: "/registration-requests",
	},
	{
		name: "Companies",
		icon: <BusinessCenterIcon />,
		link: "/companies",
	},
	{
		name: "Library",
		icon: <LocalLibraryIcon />,
		link: "/library",
	},
];

export const sideNavigationItemsClubAdmin: Array<SideNavigationItem> = [
	...sideNavigationItemsClubModerator,
	{
		name: "Moderators",
		icon: <ManageAccountsIcon />,
		link: "/admins",
	},
];

export const sideNavigationItemsAdmin: Array<SideNavigationItem> = [
	{
		name: "Clubs",
		icon: <BusinessIcon />,
		link: "/clubs",
	},
	{
		name: "Admins",
		icon: <ManageAccountsIcon />,
		link: "/admins",
	},
];
