import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import cn from "classnames";
import { UserType } from "../../types";
import ps from "./css/user.module.scss";
import { Box, LinearProgress } from "@mui/material";
import DEFAULT_USER_IMG from "../../imgs/Default_user_img.png";
import { useNavigate } from "react-router-dom";

interface UserTableType {
	users: UserType[];
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
	rowsPerPage: number;
	isLoading: boolean;
	totalPages: number;
}

interface Column {
	id: string;
	label: string;
	minWidth?: number;
	width?: number;
	align?: "right" | "center" | "left";
	format?: (value: number) => string;
}

const columns: readonly Column[] = [
	{ id: "img", label: "", width: 64 },
	{ id: "name", label: "Name", minWidth: 100 },
	{ id: "email", label: "Email", minWidth: 100 },
	{
		id: "status",
		label: "Status",
		minWidth: 100,
	},
];

interface Row {
	id: string;
	img: JSX.Element;
	name: string;
	status: string;
	email: string;
	[key: string]: any;
}

export default function UsersTable({ users, page, rowsPerPage, isLoading, totalPages, setPage, setRowsPerPage }: UserTableType) {
	const navigate = useNavigate();

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const rows = users?.map((user): Row => {
		const { firstName = "", lastName = "", imageLink, status, id, email } = user;
		return {
			id,
			img: (
				<img
					className={cn(ps.avatar)}
					src={imageLink || DEFAULT_USER_IMG}
					alt={firstName}
					onError={(ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
						(ev.target as HTMLImageElement).src = DEFAULT_USER_IMG;
					}}
				/>
			),
			name: `${firstName} ${lastName}`,
			status: status,
			email: email,
		};
	});

	const userCellClickHandle = (id: string) => {
		navigate(`/user/${id}`);
	};

	return (
		<div className={cn(ps["search-table"])}>
			<TableContainer sx={{ width: "100%", overflow: "hidden" }}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell key={column.id} style={{ minWidth: column.minWidth, width: column.width }} sx={{ p: 1 }}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
							return (
								<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
									{columns.map((column) => {
										const value = row[column.id];
										return (
											<TableCell key={column.id} align={column.align} sx={{ p: 1, cursor: "pointer" }} onClick={() => userCellClickHandle(row.id)}>
												{column.format && typeof value === "number" ? column.format(value) : value}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{isLoading && (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			)}
			<TablePagination
				style={{ marginTop: "auto" }}
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={totalPages * rowsPerPage}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</div>
	);
}
