import { useRecoilState } from "recoil";
import { confirmModalAtom } from "../atoms";

interface UseConfirmModalProps {
	title?: string;
	onConfirm: ({ itemData }?: any) => void;
}

export default function useConfirmModal({ title: genTitle = "Are you sure?", onConfirm }: UseConfirmModalProps) {
	const [modalState, setModalState] = useRecoilState(confirmModalAtom);

	return {
		closeModal: () => setModalState({ ...modalState, open: false }),
		openConfirmModal: ({ itemData, title: specTitle }: any) =>
			setModalState({
				...modalState,
				open: true,
				title: specTitle || genTitle,
				itemData,
				onConfirm: () => onConfirm({ itemData }),
			}),
	};
}
