import { useState, useEffect } from "react";
import Companies from "./Companies";
import { CompanyType } from "../../types";
import { API } from "../../queries/api";
import useSelfUser from "../../hooks/useSelfUser";
import useSnackbar from "../../hooks/useSnackbar";
import { Box, LinearProgress, Paper, Typography } from "@mui/material";
import useDebounce from "../../hooks/useDebounce";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";

export default function CompaniesList() {
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [companies, setCompanies] = useState<CompanyType[]>([]);
	const [loading, setLoading] = useState(true);
	const { clubId } = useSelfUser();
	const openSnackbar = useSnackbar();
	const debouncedSearchTerm = useDebounce(searchQuery, 500);

	const getCompanies = async (search = "") => {
		setLoading(true);
		try {
			const { data, status } = await API.get(
				`companies?idClub=${clubId}&search=${search}`
			);
			if (status === 200 && !!data.length) {
				setCompanies(data);
			} else if (status === 200 && !data.length) {
				setCompanies([]);
				openSnackbar("No companies found");
			} else {
				openSnackbar("Error fetching companies");
				setCompanies([]);
			}
		} catch (error) {
			console.log(error);
			openSnackbar("Error fetching companies");
			setCompanies([]);
		}
		setLoading(false);
	};

	useEffect(() => {
		getCompanies();
	}, []);

	useEffect(() => {
		getCompanies(debouncedSearchTerm);
	}, [debouncedSearchTerm]);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event?.target?.value);
	};

	return (
		<>
			<Paper
				sx={{
					p: 2,
				}}
			>
				<Typography variant="h6" gutterBottom>
					Search for companies
				</Typography>
				<TextField
					label="Search"
					variant="outlined"
					fullWidth
					value={searchQuery}
					onChange={handleSearchChange}
					sx={{ mb: 1 }}
					InputProps={{
						endAdornment: (
							<IconButton type="button" sx={{ p: "10px" }} aria-label="search">
								<SearchIcon />
							</IconButton>
						),
					}}
				/>
			</Paper>
			{loading ? (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			) : (
				<Companies companies={companies} idClub={clubId || ""} />
			)}
		</>
	);
}
