import { atom } from "recoil";
import { UserType } from "../types";

export const snackbarAtom = atom({
	key: "snackbarMessage",
	default: "",
});

export const confirmModalAtom = atom({
	key: "confirmModal",
	default: {
		open: false,
		title: "",
		itemData: {},
		onConfirm: () => {},
	},
});

export const selfUserAtom = atom<UserType | null>({
	key: "selfUser",
	default: null,
});
