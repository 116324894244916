import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { API } from "../../queries/api";
import useDebounce from "../../hooks/useDebounce";
import UsersTable from "./UsersTable";
import { UserType } from "../../types";
import useSelfUser from "../../hooks/useSelfUser";

interface SearchUserProps {
	clubId?: string;
}

const SearchUser = ({ clubId }: SearchUserProps) => {
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [searchResults, setSearchResults] = useState<UserType[]>([]);
	const [error, setError] = useState<string>("");
	const [page, setPage] = useState(0);
	const [size, setSize] = useState(10);
	const [allUsers, setAllUsers] = useState<UserType[]>([]);
	const [displayMode, setDisplayMode] = useState<"ALL" | "SEARCH">("ALL");
	const [isLoading, setLoading] = useState(false);
	const [totalPages, setTotalPages] = useState(0);
	const { clubId: selfClubId } = useSelfUser();
	const searchClubId = clubId || selfClubId;
	const debouncedSearchTerm = useDebounce(searchQuery, 500);

	const getAllUsers = async () => {
		try {
			setLoading(true);
			const payload = {
				startDate: "2020-10-03T20:42:30.519Z",
				endDate: "2029-10-03T20:42:30.519Z",
				page: page,
				size: size,
				reverse: true,
			};
			const { data, status } = await API.post(`users?idClub=${searchClubId}`, payload);
			if (status === 200 && !!data?.items?.length) {
				const allUniqueUsers = [...allUsers, ...data.items].filter((user, index, self) => index === self.findIndex((t) => t.id === user.id));
				setAllUsers(allUniqueUsers);
				setTotalPages(data.totalPages);
			} else {
				setAllUsers([]);
			}
		} catch (error) {
			console.log(error);
			setAllUsers([]);
		}
		setLoading(false);
	};

	useEffect(() => {
		getAllUsers();
	}, []);

	useEffect(() => {
		if (debouncedSearchTerm.trim().length === 0) {
			setError("");
			setSearchResults([]);
			setDisplayMode("ALL");
			getAllUsers();
		} else if (debouncedSearchTerm.trim().length < 3) {
			setError("Please enter a search query. (3+ characters)");
			setSearchResults([]);
			getAllUsers();
		} else {
			setDisplayMode("SEARCH");
			searchUsers(debouncedSearchTerm);
			setError("");
		}
	}, [debouncedSearchTerm, size, page]);

	const searchUsers = async (query: string) => {
		try {
			setLoading(true);
			const payload = {
				startDate: "2020-10-03T20:42:30.519Z",
				endDate: "2029-10-03T20:42:30.519Z",
				page: page,
				size: size,
				reverse: true,
			};
			const { data, status } = await API.post(`users/search?searchStr=${query}&idClub=${searchClubId}`, payload);
			const users = data?.items;
			if (status === 200 && !!users.length) {
				setSearchResults(data?.items);
			} else {
				setSearchResults([]);
			}
		} catch (error) {
			console.log(error);
			setSearchResults([]);
		}
		setLoading(false);
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event?.target?.value);
	};

	return (
		<Paper
			sx={{
				p: 2,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography component="h2" variant="h6" color="primary" gutterBottom>
				User Search
			</Typography>
			<div style={{ marginBottom: 20 }}>
				<TextField
					label="Search for users"
					variant="outlined"
					fullWidth
					value={searchQuery}
					onChange={handleSearchChange}
					sx={{ mb: 1 }}
					InputProps={{
						endAdornment: (
							<IconButton type="button" sx={{ p: "10px" }} aria-label="search">
								<SearchIcon />
							</IconButton>
						),
					}}
				/>
				{error && <Typography color="error">{error}</Typography>}
			</div>
			<UsersTable
				isLoading={isLoading}
				setPage={setPage}
				page={page}
				setRowsPerPage={setSize}
				rowsPerPage={size}
				totalPages={totalPages}
				users={displayMode === "ALL" ? allUsers : searchResults}
			/>
		</Paper>
	);
};

export default SearchUser;
