import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { useNavigate } from "react-router-dom";
import { API } from "../../queries/api";
import { ClubType } from "../../types";
import { Button } from "@mui/material";

export default function Clubs() {
	const [clubs, setClubs] = useState<ClubType[]>([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const getClubs = async () => {
		try {
			setLoading(true);
			const { data, status } = await API.get(`clubs`);
			if (status === 200 && !!data.length) {
				setClubs(data);
			} else {
				setClubs([]);
			}
		} catch (error) {
			console.log(error);
			setClubs([]);
		}
		setLoading(false);
	};

	useEffect(() => {
		getClubs();
	}, []);

	const clubClickHandle = (id: string) => {
		navigate(`/club/${id}`);
	};

	return (
		<Paper
			sx={{
				p: 2,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography component="h2" variant="h6" color="primary" gutterBottom>
				Clubs
			</Typography>
			{loading && (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			)}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Created</TableCell>
							<TableCell>Updated</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{clubs.map((row) => (
							<TableRow key={row.name} sx={{ cursor: "pointer" }} onClick={() => clubClickHandle(row.id)}>
								<TableCell>{row.name}</TableCell>
								<TableCell>{row.created}</TableCell>
								<TableCell>{row.updated}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "16px" }} onClick={() => navigate(`/club/create`)}>
				<Button variant="contained" color="primary">
					Add Club
				</Button>
			</div>
		</Paper>
	);
}
