import {
	Avatar,
	Button,
	Divider,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import { CompanyPeopleType, CompanyType, KeywordType } from "../../types";
import { useState } from "react";
import { ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useConfirmModal from "../../hooks/useConfirmModal";
import { API } from "../../queries/api";
import useSnackbar from "../../hooks/useSnackbar";
import { useFormikContext } from "formik";

type CompanyPeopleProps = {
	companyRoles: KeywordType[];
	person: CompanyPeopleType;
};

export default function PeopleItem({
	person,
	companyRoles,
}: CompanyPeopleProps) {
	const { user } = person;
	const openSnackbar = useSnackbar();
	const { values: company, setFieldValue } = useFormikContext<CompanyType>();
	const userName = `${user.firstName} ${user.lastName}`;
	const [isEdit, setIsEdit] = useState(false);
	const [selectedRole, setSelectedRole] = useState<KeywordType | undefined>(
		person.role
	);

	const { openConfirmModal } = useConfirmModal({
		onConfirm: () => {
			removePerson();
		},
		title: `Are you sure you want to remove ${userName} from the ${company.name} company?`,
	});

	const removePerson = async () => {
		try {
			const filteredCompanyPeople = company?.people?.filter(
				(p: CompanyPeopleType) => p.id !== person.id
			);
			const payload = {
				...company,
				people: filteredCompanyPeople,
			};

			const { status } = await API.post(`company`, payload);
			if (status === 200) {
				openSnackbar("User successfully removed from the company");
				setFieldValue("people", filteredCompanyPeople);
			} else {
				openSnackbar("Error removing person from company");
			}
		} catch (error) {
			console.error("Error removing person from company:", error);
			openSnackbar("Error removing person from company");
		}
	};

	const handleChangeRole = (event: SelectChangeEvent) => {
		const role = companyRoles.find((role) => role.word === event.target.value);
		setSelectedRole(role);
	};

	const handleSaveRole = async () => {
		try {
			const updatedPeople = company?.people?.map((p) =>
				p.id === person.id ? { ...p, role: selectedRole } : p
			);
			const payload = {
				...company,
				people: updatedPeople,
			};

			const { status } = await API.post(`company`, payload);
			if (status === 200) {
				openSnackbar("User role successfully updated");
				setFieldValue("people", updatedPeople);
				setIsEdit(false);
			} else {
				openSnackbar("Error updating user role");
			}
		} catch (error) {
			console.error("Error updating user role:", error);
			openSnackbar("Error updating user role");
		}
	};

	return (
		<>
			<ListItem
				secondaryAction={
					isEdit ? (
						<Button
							variant="contained"
							color="primary"
							onClick={handleSaveRole}
							sx={{ marginTop: "12px" }}
						>
							Save
						</Button>
					) : (
						<>
							<IconButton edge="end" aria-label="edit">
								<EditIcon onClick={() => setIsEdit(true)} />
							</IconButton>
							<IconButton edge="end" aria-label="delete">
								<DeleteIcon onClick={openConfirmModal} />
							</IconButton>
						</>
					)
				}
			>
				<ListItemAvatar>
					<Avatar src={user.imageLink} />
				</ListItemAvatar>
				{isEdit ? (
					<FormControl sx={{ width: "200px", marginTop: 2 }}>
						<InputLabel id="role">Select role</InputLabel>
						<Select
							labelId="role"
							id="role"
							value={selectedRole?.word}
							label="Select role"
							onChange={handleChangeRole}
						>
							{companyRoles.map((role) => (
								<MenuItem key={role.id} value={role.word}>
									{role.word}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : (
					<>
						<ListItemText primary={userName} secondary={person.role.word} />
					</>
				)}
			</ListItem>
			<Divider variant="inset" component="li" />
		</>
	);
}
