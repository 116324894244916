import {
	Autocomplete,
	Avatar,
	Button,
	Card,
	CardContent,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import { CompanyType, KeywordType } from "../../types";
import ps from "./css/companies.module.scss";
import cn from "classnames";
import useSnackbar from "../../hooks/useSnackbar";
import { Form, Formik, useFormikContext } from "formik";
import { API } from "../../queries/api";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import useConfirmModal from "../../hooks/useConfirmModal";
import { IMAGE_TYPES } from "../../constants";
import ImageUpload from "../../components/image-upload/ImageUpload";
import CompanyPeople from "./CompanyPeople";
import { ICountry, countries } from "countries-list";

const countriesOptions: { [key: string]: ICountry } = {
	all: {
		name: "All",
		capital: "",
		continent: "AF",
		currency: [],
		languages: [],
		native: "",
		phone: [],
	},
	...countries,
};

const countriesOptionsArray: readonly string[] = Object.keys(countriesOptions);

interface CompanyProps {
	company: CompanyType;
	industriesOptions: KeywordType[];
	industryCategoriesOptions: KeywordType[];
	companyRoles: KeywordType[];
	onNewCompanyCreated?: (company: CompanyType) => void;
}

interface CompanyFormProps {
	industriesOptions: KeywordType[];
	industryCategoriesOptions: KeywordType[];
	companyRoles: KeywordType[];
}

interface ContactProps {
	index: number;
}

function Contact({ index }: ContactProps) {
	const { values, handleChange } = useFormikContext<CompanyType>();
	const { email, firstName, lastName, phoneNumber, position } =
		values?.contacts[index];

	return (
		<Grid
			container
			spacing={2}
			alignItems="center"
			className={cn(ps["contact-item"])}
		>
			<Grid item xs={6}>
				<TextField
					name={`contacts[${index}]firstName`}
					label="First Name"
					fullWidth
					variant="outlined"
					value={firstName}
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					name={`contacts[${index}]lastName`}
					label="Last Name"
					fullWidth
					variant="outlined"
					value={lastName}
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					name={`contacts[${index}]position`}
					label="Position"
					fullWidth
					variant="outlined"
					value={position}
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					name={`contacts[${index}]phoneNumber`}
					label="Phone Number"
					fullWidth
					variant="outlined"
					value={phoneNumber}
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					name={`contacts[${index}]email`}
					label="Email"
					fullWidth
					variant="outlined"
					value={email}
					onChange={handleChange}
				/>
			</Grid>
		</Grid>
	);
}

function CompanyForm({
	industriesOptions,
	industryCategoriesOptions,
	companyRoles,
}: CompanyFormProps) {
	const { values, handleChange, setFieldValue, handleSubmit } =
		useFormikContext<CompanyType>();
	const {
		name,
		image,
		description,
		mainOffice,
		website,
		contacts,
		industries,
		industryCategories,
		id,
		people,
		providesServices,
	} = values;
	const [openMenu, setOpenMenu] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [industryCategoriesLocalState, setIndustryCategoriesLocalState] =
		useState<any>(industryCategories);
	const { openConfirmModal: handleOpenDeleteModal } = useConfirmModal({
		title: `Are you really sure you want to delete ${name} company?`,
		onConfirm: () => {
			deleteCompany();
		},
	});
	const openSnackbar = useSnackbar();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setOpenMenu(true);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpenMenu(false);
	};

	const deleteCompany = async () => {
		try {
			const { status } = await API.delete(`company/${id}`);
			if (status === 200) {
				openSnackbar("Company deleted successfully");
			} else {
				openSnackbar("Error deleting company");
			}
		} catch (error) {
			console.log(error);
			openSnackbar("Error deleting company");
		}
	};

	const imageUploadHandle = (imageLink: string) => {
		setFieldValue("image", imageLink);
	};

	return (
		<Card variant="outlined" className={cn(ps.company)}>
			<CardContent>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={12}>
						<div className={cn(ps["company-header"])}>
							<Avatar
								alt={name}
								src={image}
								sx={{ width: 100, height: 100, marginRight: "16px" }}
							/>
							<TextField
								name="name"
								label="Name"
								fullWidth
								variant="outlined"
								value={name}
								onChange={handleChange}
							/>
							<div style={{ marginLeft: "16px" }}>
								<IconButton
									aria-label="more"
									id="long-button"
									aria-controls={openMenu ? "long-menu" : undefined}
									aria-expanded={openMenu ? "true" : undefined}
									aria-haspopup="true"
									onClick={handleClick}
								>
									<MoreVertIcon />
								</IconButton>
								<Menu
									id="long-menu"
									MenuListProps={{
										"aria-labelledby": "long-button",
									}}
									anchorEl={anchorEl}
									open={openMenu}
									onClose={handleClose}
								>
									<MenuItem onClick={handleOpenDeleteModal}>
										Delete Company
									</MenuItem>
								</Menu>
							</div>
						</div>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="description"
							label="Description"
							fullWidth
							variant="outlined"
							value={description}
							multiline
							onChange={handleChange}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							name="mainOffice"
							label="Main Office"
							fullWidth
							variant="outlined"
							value={mainOffice}
							onChange={handleChange}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							name="website"
							label="Website"
							fullWidth
							variant="outlined"
							value={website}
							onChange={handleChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<Autocomplete
							options={industriesOptions || []}
							getOptionLabel={(option) => option?.word}
							defaultValue={industries[0]}
							filterSelectedOptions
							onChange={(e, value) => {
								setFieldValue("industries", [value]);
								setIndustryCategoriesLocalState([]);
								setFieldValue("industryCategories", []);
							}}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							renderInput={(params) => (
								<TextField
									{...params}
									name="industries"
									label="Industries"
									placeholder="Industries"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Autocomplete
							multiple
							disabled={!industries.length || !!!industries[0]}
							options={
								industryCategoriesOptions.filter(
									(category) => category.parentKeyWord?.id === industries[0]?.id
								) || []
							}
							getOptionLabel={(option) => option?.word}
							value={industryCategoriesLocalState}
							filterSelectedOptions
							onChange={(e, value) => {
								setIndustryCategoriesLocalState(value);
								setFieldValue("industryCategories", value);
							}}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							renderInput={(params) => (
								<TextField
									{...params}
									name="industryCategories"
									label="Industry Categories"
									placeholder="Industry Categories"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Autocomplete
							multiple
							options={countriesOptionsArray}
							getOptionLabel={(option) => countriesOptions[option]?.name}
							defaultValue={providesServices || []}
							filterSelectedOptions
							value={providesServices || []}
							onChange={(e, value) => {
								const updatedValue = value;
								if (updatedValue.includes("all")) {
									setFieldValue("providesServices", ["all"]);
								} else {
									setFieldValue("providesServices", updatedValue);
								}
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									name="providesServices"
									label="Provides Services"
									placeholder="Provides Services"
								/>
							)}
						/>
					</Grid>
				</Grid>
				<div className={cn(ps["contacts"])}>
					<Typography variant="h6" className={cn(ps["contacts-header"])}>
						Contacts:
					</Typography>
					{!!contacts.length ? (
						contacts.map((contact, index) => (
							<Contact key={contact.id} index={index} />
						))
					) : (
						<Button
							type="button"
							variant="contained"
							color="info"
							onClick={() => setFieldValue("contacts", [...contacts, {}])}
						>
							Add Contacts
						</Button>
					)}
				</div>
				<CompanyPeople people={people} companyRoles={companyRoles} />
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
						paddingTop: "16px",
						alignItems: "center",
						gap: "16px",
					}}
				>
					<ImageUpload
						onUpload={imageUploadHandle}
						type={IMAGE_TYPES.COMPANY_PHOTO}
					/>
					<Button
						type="button"
						variant="contained"
						color="primary"
						onClick={() => handleSubmit()}
					>
						Update company
					</Button>
				</div>
			</CardContent>
		</Card>
	);
}

export default function Company({
	company,
	industriesOptions,
	industryCategoriesOptions,
	companyRoles,
	onNewCompanyCreated,
}: CompanyProps) {
	const openSnackbar = useSnackbar();

	const handleSubmit = async (values: any) => {
		try {
			const { data, status } = await API.post("company", values);
			if (status === 200) {
				openSnackbar("Company updated successfully");
				!!onNewCompanyCreated && onNewCompanyCreated(data);
			} else {
				openSnackbar("Error updating company");
			}
		} catch (error) {
			console.log(error);
			openSnackbar("Error updating company");
		}
	};

	return (
		<Formik initialValues={company} enableReinitialize onSubmit={handleSubmit}>
			<Form>
				<CompanyForm
					industriesOptions={industriesOptions}
					industryCategoriesOptions={industryCategoriesOptions}
					companyRoles={companyRoles}
				/>
			</Form>
		</Formik>
	);
}
