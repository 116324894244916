import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { API } from "../../queries/api";
import useSnackbar from "../../hooks/useSnackbar";
import { RegRequestType } from "../../types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { GENERAL_STATUSES } from "../../constants";
import { Button, Stack } from "@mui/material";
import useConfirmModal from "../../hooks/useConfirmModal";
import useSelfUser from "../../hooks/useSelfUser";

const RegRequests = () => {
	const [requests, setRequests] = useState<RegRequestType[]>([]);
	const [loading, setLoading] = useState(false);
	const [reqType, setReqType] = useState<string>(GENERAL_STATUSES.PENDING);
	const openSnackbar = useSnackbar();
	const { clubId } = useSelfUser();
	const isPending = reqType === GENERAL_STATUSES.PENDING;
	const isRejected = reqType === GENERAL_STATUSES.REJECTED;

	const { openConfirmModal: openApproveConfirmModal } = useConfirmModal({
		onConfirm: ({ itemData }) => {
			approveReqHandle(itemData.id);
		},
	});

	const { openConfirmModal: openRejectConfirmModal } = useConfirmModal({
		onConfirm: ({ itemData }) => {
			rejectReqHandle(itemData.id);
		},
	});

	const { openConfirmModal: openDeleteUserConfirmModal } = useConfirmModal({
		onConfirm: ({ itemData }) => {
			deleteUserHandle(itemData?.userDTO?.id);
		},
	});

	const handleChangeReqType = (event: SelectChangeEvent) => {
		setReqType(event.target.value as string);
	};

	const fetchRequests = async () => {
		setLoading(true);
		try {
			const { data, status } = await API.get(`registration/requests?status=${reqType}&idClub=${clubId}`);
			if (status === 200) {
				setRequests(data);
			} else {
				openSnackbar("Error fetching requests");
			}
		} catch (error) {
			console.error("Error fetching requests:", error);
			openSnackbar("Error fetching requests");
		}
		setLoading(false);
	};

	const reqHandle = async (id: string, action: "approve" | "reject", successMsg: string, errorMsg: string) => {
		try {
			const { status } = await API.post(`registration/request/${id}/${action}`);
			if (status === 200) {
				openSnackbar(successMsg);
				fetchRequests();
			} else {
				openSnackbar(errorMsg);
			}
		} catch (error) {
			console.error(errorMsg, error);
			openSnackbar(errorMsg);
		}
	};

	const rejectReqHandle = (id: string) => {
		reqHandle(id, "reject", "Request removed successfully", "Error removing request");
	};

	const approveReqHandle = (id: string) => {
		reqHandle(id, "approve", "Request approved successfully", "Error approving request");
	};

	const deleteUserHandle = async (id: string) => {
		try {
			const { status } = await API.delete(`user/${id}`);
			if (status === 200) {
				openSnackbar("User deleted successfully");
				fetchRequests();
			} else {
				openSnackbar("Error deleting user");
			}
		} catch (error) {
			console.log(error);
			openSnackbar("Error deleting user");
		}
	};

	useEffect(() => {
		fetchRequests();
	}, [reqType]);

	return (
		<Paper
			sx={{
				p: 2,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography component="h2" variant="h6" color="primary" gutterBottom>
				Registration requests
			</Typography>
			{loading && (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			)}
			<FormControl sx={{ width: "200px", my: 1 }}>
				<InputLabel id="chat-type-label">Select requests type</InputLabel>
				<Select labelId="chat-type-label" id="chat-type-label" value={reqType} label="Select keyword type" onChange={handleChangeReqType}>
					{Object.keys(GENERAL_STATUSES).map((key) => (
						<MenuItem key={key} value={key}>
							{GENERAL_STATUSES[key as keyof typeof GENERAL_STATUSES]}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Username</TableCell>
							<TableCell>Email</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{requests?.length ? (
							requests.map((row) => (
								<TableRow key={row?.id} sx={{ cursor: "pointer" }}>
									<TableCell>{`${row?.userDTO?.firstName} ${row?.userDTO?.lastName}`}</TableCell>
									<TableCell>{row?.userDTO?.username}</TableCell>
									<TableCell>{row?.userDTO?.email}</TableCell>
									<TableCell>
										{isPending && (
											<Stack direction="row" spacing={2} justifyContent="flex-end">
												<Button
													variant="outlined"
													startIcon={<CheckCircleIcon />}
													color="success"
													onClick={() =>
														openApproveConfirmModal({
															itemData: row,
															title: `Are you sure you want to approve request from ${row?.userDTO?.firstName} ${row?.userDTO?.lastName}?`,
														})
													}
												>
													Approve
												</Button>
												<Button
													variant="outlined"
													startIcon={<BlockIcon />}
													color="error"
													onClick={() =>
														openRejectConfirmModal({
															itemData: row,
															title: `Are you sure you want to reject request from ${row?.userDTO?.firstName} ${row?.userDTO?.lastName}?`,
														})
													}
												>
													Reject
												</Button>
											</Stack>
										)}
										{isRejected && (
											<Stack direction="row" spacing={2} justifyContent="flex-end">
												<Button
													variant="outlined"
													startIcon={<CheckCircleIcon />}
													color="success"
													onClick={() =>
														openApproveConfirmModal({
															itemData: row,
															title: `Are you sure you want to approve request from ${row?.userDTO?.firstName} ${row?.userDTO?.lastName}?`,
														})
													}
												>
													Approve
												</Button>
												<Button
													variant="outlined"
													startIcon={<BlockIcon />}
													color="error"
													onClick={() =>
														openDeleteUserConfirmModal({
															itemData: row,
															title: `Are you sure you want to delete user ${row?.userDTO?.firstName} ${row?.userDTO?.lastName}?`,
														})
													}
												>
													Delete user
												</Button>
											</Stack>
										)}
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={4} align="center">
									No requests
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

export default RegRequests;
