import { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate, useParams } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { API } from "../../queries/api";
import { ClubType } from "../../types";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useConfirmModal from "../../hooks/useConfirmModal";
import useSnackbar from "../../hooks/useSnackbar";
import SearchUser from "../user/SearchUser";
import useSelfUser from "../../hooks/useSelfUser";

interface ChatType {
	id: string;
	idClub: string;
	name: string;
	created: string;
	updated: string;
}

export default function Club() {
	const [loading, setLoading] = useState(true);
	const [club, setClub] = useState<ClubType | null>();
	const [chats, setChats] = useState<ChatType[]>([]);
	const { isSuperAdmin, clubId: selfClubId, hasClubLevelAccess } = useSelfUser();
	const isUsersVisible = isSuperAdmin;
	const isChatListVisible = hasClubLevelAccess; // TODO: prevent fetching chats if not visible
	const { clubId: routeClubId } = useParams<{ clubId: string }>();
	const clubId = routeClubId || selfClubId;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const navigate = useNavigate();
	const { openConfirmModal: openFinalConfirmModal } = useConfirmModal({
		title: `Are you really sure you want to delete ${club?.name} club? All chats, users, assets etc will be deleted. This action is irreversible.`,
		onConfirm: () => {
			deleteClub();
		},
	});
	const { openConfirmModal, closeModal } = useConfirmModal({
		title: `Are you sure you want to delete ${club?.name} club?`,
		onConfirm: () => {
			closeModal();
			setTimeout(() => {
				openFinalConfirmModal({});
			}, 600);
		},
	});
	const openSnackbar = useSnackbar();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const getClub = async () => {
		try {
			setLoading(true);
			const { data, status } = await API.get(`club/${clubId}`);
			if (status === 200 && !!data) {
				setClub(data);
				getChats();
			} else {
				setClub(null);
			}
		} catch (error) {
			console.log(error);
			setClub(null);
		}
		setLoading(false);
	};

	const getChats = async () => {
		try {
			setLoading(true);
			const { data, status } = await API.get(`getstream/club/${clubId}/club_chats`);
			if (status === 200 && !!data) {
				setChats(data);
			} else {
				setChats([]);
			}
		} catch (error) {
			console.log(error);
			setChats([]);
		}
		setLoading(false);
	};

	const deleteClub = async () => {
		try {
			const { status } = await API.delete(`club/${club?.id}`);
			if (status === 200) {
				openSnackbar("Club deleted successfully");
				navigate("/clubs");
			}
		} catch (error) {
			openSnackbar("Error deleting club");
			console.error("Error deleting club:", error);
		}
	};

	useEffect(() => {
		getClub();
	}, []);

	return (
		<>
			<Paper
				sx={{
					p: 2,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						{club?.name}
					</Typography>
					<div>
						<IconButton
							aria-label="more"
							id="long-button"
							aria-controls={openMenu ? "long-menu" : undefined}
							aria-expanded={openMenu ? "true" : undefined}
							aria-haspopup="true"
							onClick={handleClick}
						>
							<MoreVertIcon />
						</IconButton>
						<Menu
							id="long-menu"
							MenuListProps={{
								"aria-labelledby": "long-button",
							}}
							anchorEl={anchorEl}
							open={openMenu}
							onClose={handleClose}
						>
							<MenuItem onClick={() => navigate(`/club/${clubId}/edit`)}>Edit Club</MenuItem>
							{isSuperAdmin && <MenuItem onClick={openConfirmModal}>Delete Club</MenuItem>}
						</Menu>
					</div>
				</div>
				{loading && (
					<Box sx={{ width: "100%", my: 0.5 }}>
						<LinearProgress />
					</Box>
				)}
				{isChatListVisible && (
					<>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Chat Name</TableCell>
										<TableCell>Created</TableCell>
										<TableCell>Updated</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{chats.map((row) => (
										<TableRow key={row.id} sx={{ cursor: "pointer" }} onClick={() => navigate(`/club/${clubId}/chat/${row.id}`)}>
											<TableCell>{row.name}</TableCell>
											<TableCell>{row.created}</TableCell>
											<TableCell>{row.updated}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						<div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "16px" }}>
							<Button variant="contained" color="primary" onClick={() => navigate(`/club/${clubId}/chat/create`)}>
								Create Chat
							</Button>
						</div>
					</>
				)}
			</Paper>
			{isUsersVisible && (
				<div style={{ marginTop: "32px" }}>
					<SearchUser clubId={clubId} />
				</div>
			)}
		</>
	);
}
