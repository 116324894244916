import { useState } from "react";
import Button from "@mui/material/Button";
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Typography } from "@mui/material";
import { InterestUsersType, UserType } from "../../types";
import ps from "./css/addUsersByInterestsModal.module.scss";
import cn from "classnames";
import DEFAULT_USER_IMG from "../../imgs/Default_user_img.png";
import Grid from "@mui/material/Grid";

interface AddUsersByInterestsModalProps {
	isOpen: boolean;
	interests: InterestUsersType[];
	onClose: () => void;
	onConfirm: (users: UserType[]) => void;
}

export default function AddUsersByInterestsModal({ isOpen, interests, onClose, onConfirm }: AddUsersByInterestsModalProps) {
	const [selectedUsers, setSelectedUsers] = useState<UserType[]>([]);

	const handleCloseModal = () => {
		onClose();
	};

	const selectUserHandle = (user: UserType) => {
		setSelectedUsers((prev) => {
			if (prev.find((u) => u.id === user.id)) {
				return prev;
			}
			return [...prev, user];
		});
	};

	const deselectUserHandle = (user: UserType) => {
		setSelectedUsers((prev) => prev.filter((u) => u.id !== user.id));
	};

	const addUsersHandle = () => {
		onConfirm(selectedUsers);
		onClose();
		setSelectedUsers([]);
	};

	const addAllUsersHandle = () => {
		const allUsers = interests.map((interest) => interest.users).flat();
		const uniqueUsers = allUsers.filter((user, index, self) => self.findIndex((u) => u.id === user.id) === index);
		setSelectedUsers(uniqueUsers);
	};

	return (
		<Dialog open={isOpen} onClose={handleCloseModal} fullWidth>
			<DialogTitle id="alert-dialog-title">Add users to chat</DialogTitle>
			<DialogContent>
				<Grid container spacing={3} sx={{ py: 1 }}>
					<Grid item xs={6}>
						<div className={cn(ps["search-table"])}>
							{!!interests.length &&
								interests.map((interest) => {
									return (
										<div key={interest.id} className={cn(ps["table-category-item"])}>
											<Typography variant="subtitle2">{interest.word}</Typography>
											{interest?.users?.length &&
												interest?.users?.map((user) => {
													return (
														<div className={cn(ps["table-item"])} key={user.id}>
															<img
																className={cn(ps.avatar)}
																src={user.imageLink || DEFAULT_USER_IMG}
																alt={user.firstName}
																onError={(ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
																	(ev.target as HTMLImageElement).src = DEFAULT_USER_IMG;
																}}
															/>
															<div>
																{user.firstName} {user.lastName}
															</div>
															<Button size="small" onClick={() => selectUserHandle(user)} sx={{ marginLeft: "auto" }}>
																Add
															</Button>
														</div>
													);
												})}
										</div>
									);
								})}
						</div>
					</Grid>
					<Grid item xs={6}>
						<DialogContentText variant="body1">Selected users:</DialogContentText>
						<div className={cn(ps["search-table"])}>
							{!!selectedUsers.length ? (
								selectedUsers.map((user) => {
									return (
										<div className={cn(ps["table-item"])} key={user.id}>
											<img
												className={cn(ps.avatar)}
												src={user.imageLink || DEFAULT_USER_IMG}
												alt={user.firstName}
												onError={(ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
													(ev.target as HTMLImageElement).src = DEFAULT_USER_IMG;
												}}
											/>
											<div>
												{user.firstName} {user.lastName}
											</div>
											<Button size="small" onClick={() => deselectUserHandle(user)} sx={{ marginLeft: "auto" }}>
												Remove
											</Button>
										</div>
									);
								})
							) : (
								<DialogContentText>
									<div className={cn(ps["add-all-container"])}>
										<Typography variant="subtitle2" className={cn(ps["title"])}>
											No users selected
										</Typography>
										<Button color="info" variant="contained" onClick={addAllUsersHandle}>
											Add all users
										</Button>
									</div>
								</DialogContentText>
							)}
						</div>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseModal} variant="outlined">
					Cancel
				</Button>
				<Button onClick={addUsersHandle} variant="contained" disabled={!selectedUsers.length}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
