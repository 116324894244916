import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { API } from "../../queries/api";
import useSnackbar from "../../hooks/useSnackbar";
import { RequestType } from "../../types";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";
import countryList from "country-list";
import useConfirmModal from "../../hooks/useConfirmModal";
import useSelfUser from "../../hooks/useSelfUser";

const Requests = () => {
	const [requests, setRequests] = useState<RequestType[]>([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [size, setSize] = useState(10);
	const openSnackbar = useSnackbar();
	const { clubId } = useSelfUser();
	const { openConfirmModal } = useConfirmModal({
		onConfirm: ({ itemData }) => {
			removeReq(itemData.id);
		},
	});

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSize(+event.target.value);
		setPage(0);
	};

	const fetchRequests = async () => {
		setLoading(true);
		try {
			const payload = {
				page,
				size,
				reverse: true,
			};
			const { data, status } = await API.post(`requests?idClub=${clubId}`, payload);
			if (status === 200 && !!data?.items?.length) {
				setRequests(data.items);
				setTotalPages(data.totalPages);
			} else if (status === 200 && !data?.items?.length) {
				setRequests([]);
				setTotalPages(0);
				openSnackbar("No requests found");
			} else {
				openSnackbar("Error fetching requests");
			}
		} catch (error) {
			console.error("Error fetching requests:", error);
			openSnackbar("Error fetching requests");
		}
		setLoading(false);
	};

	const cellContent = (content: any, key?: string) => {
		if (!content || !content.length) {
			return "-";
		}
		if (typeof content === "string") {
			return <Typography variant="caption">{content}</Typography>;
		}
		const arrayContent = content.map((item: any, index: number) => {
			if (key === "location") {
				return item === "all" ? "All" : `${countryList.getName(item)}${index !== content.length - 1 ? ", " : ""}`;
			}
			if (key) {
				return `${item[key]}${index !== content.length - 1 ? ", " : ""}`;
			}
			return `${item}${index !== content.length - 1 ? ", " : ""}`;
		});

		return <Typography variant="caption">{arrayContent}</Typography>;
	};

	const removeReq = async (id: string) => {
		try {
			const { status } = await API.delete(`request/${id}`);
			if (status === 200) {
				openSnackbar("Request removed successfully");
				fetchRequests();
			} else {
				openSnackbar("Error removing request");
			}
		} catch (error) {
			console.error("Error removing request:", error);
			openSnackbar("Error removing request");
		}
	};

	useEffect(() => {
		fetchRequests();
	}, [page, size]);

	return (
		<Paper
			sx={{
				p: 2,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography component="h2" variant="h6" color="primary" gutterBottom>
				Market requests
			</Typography>
			{loading && (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			)}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Title</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>Description</TableCell>
							<TableCell>Industry</TableCell>
							<TableCell>Industry Category</TableCell>
							<TableCell>Location</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{requests.map((row) => (
							<TableRow key={row.id} sx={{ cursor: "pointer" }}>
								<TableCell>{cellContent(row.title)}</TableCell>
								<TableCell>{cellContent(`${row.userDTO?.firstName} ${row.userDTO?.lastName}`)}</TableCell>
								<TableCell>{cellContent(row.description)}</TableCell>
								<TableCell>{cellContent(row.industries, "word")}</TableCell>
								<TableCell>{cellContent(row.industryCategories, "word")}</TableCell>
								<TableCell>{cellContent(row.locations, "location")}</TableCell>
								<TableCell>
									<div style={{ display: "flex" }}>
										{/* <Tooltip title="Approve" placement="top">
											<IconButton aria-label="Approve" onClick={() => {}}>
												<CheckCircleIcon />
											</IconButton>
										</Tooltip> */}
										<Tooltip title="Remove" placement="top">
											<IconButton
												aria-label="Remove"
												onClick={() =>
													openConfirmModal({
														itemData: row,
														title: `Are you sure you want to remove "${row.title}" request?`,
													})
												}
											>
												<DeleteIcon />
											</IconButton>
										</Tooltip>
									</div>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				style={{ marginTop: "auto" }}
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={totalPages * size}
				rowsPerPage={size}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
};

export default Requests;
