import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../queries/api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import GetstreamChat from "../../components/getstream";
import useSnackbar from "../../hooks/useSnackbar";

export default function ClubChat() {
	const [loading, setLoading] = useState(true);
	const [chat, setChat] = useState<any>();
	const { chatId, clubId } = useParams();
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const navigate = useNavigate();
	const openSnackbar = useSnackbar();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClickOpenModal = () => {
		setOpen(true);
	};

	const handleCloseModal = () => {
		setOpen(false);
	};

	const getChat = async () => {
		try {
			setLoading(true);
			const { data, status } = await API.get(`getstream/club_chat/${chatId}`);
			if (status === 200 && !!data) {
				setChat(data);
			} else {
				setChat(null);
			}
		} catch (error) {
			console.log(error);
			setChat(null);
		}
		setLoading(false);
	};

	const deleteChat = async () => {
		try {
			setLoading(true);
			const { data, status } = await API.delete(`getstream/club_chat/${chatId}`);
			if (status === 200 && !!data) {
				openSnackbar("Chat deleted successfully");
				navigate(`/club`);
			} else {
				openSnackbar("Error deleting chat");
			}
		} catch (error) {
			console.log(error);
			openSnackbar("Error deleting chat");
		}
		setLoading(false);
	};

	useEffect(() => {
		getChat();
	}, []);

	return (
		<Paper
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			{loading && (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			)}
			<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "8px" }}>
				<Typography
					component="h2"
					variant="h6"
					color="primary"
					gutterBottom
					sx={{
						px: 2,
						pt: 1,
						pb: 0,
					}}
				>
					{chat?.name} Chat
				</Typography>
				<div>
					<IconButton
						aria-label="more"
						id="long-button"
						aria-controls={openMenu ? "long-menu" : undefined}
						aria-expanded={openMenu ? "true" : undefined}
						aria-haspopup="true"
						onClick={handleClick}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id="long-menu"
						MenuListProps={{
							"aria-labelledby": "long-button",
						}}
						anchorEl={anchorEl}
						open={openMenu}
						onClose={handleClose}
					>
						<MenuItem onClick={handleClickOpenModal}>Delete Chat</MenuItem>
					</Menu>
				</div>
			</div>
			<div>
				{!!chatId ? <GetstreamChat id={chatId} /> : <Typography variant="body1">Chat not found</Typography>}
				<Dialog open={open} onClose={handleCloseModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">Are you sure you want to delete this chat?</DialogTitle>
					<DialogActions>
						<Button onClick={handleCloseModal} variant="outlined">
							Cancel
						</Button>
						<Button onClick={deleteChat} variant="contained" color="error">
							Delete
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</Paper>
	);
}
