import { useEffect } from "react";
import { Modal, Box, Typography } from "@mui/material";
import { useState } from "react";
import { modalStyles } from "../../constants/modalStyles";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { EDIT_KW_MODAL_TYPES, KEYWORD_TYPES } from "../../constants";
import { SelectChangeEvent } from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import useSnackbar from "../../hooks/useSnackbar";
import { API } from "../../queries/api";
import { KeywordType } from "../../types";
import useSelfUser from "../../hooks/useSelfUser";

type EditKWModalProps = {
	state: {
		type: string;
		keyword?: KeywordType;
	};
	defKeywordType?: string;
	onSave: () => void;
	onClose: () => void;
};

export default function EditKWModal({ state: { type, keyword }, defKeywordType, onClose, onSave }: EditKWModalProps) {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [keywordType, setKeywordType] = useState<string>(defKeywordType || KEYWORD_TYPES.PERSONAL_INTERESTS);
	const [word, setWord] = useState("");
	const [title, setTitle] = useState("");
	const [parentKeyword, setParentKeyword] = useState<KeywordType | null>(null);
	const openSnackbar = useSnackbar();
	const { clubId } = useSelfUser();

	const handleChangeKWType = (event: SelectChangeEvent) => {
		setKeywordType(event.target.value as string);
	};

	useEffect(() => {
		if (type === EDIT_KW_MODAL_TYPES.NEW) {
			setOpen(true);
			setTitle("Create new keyword");
		} else if (type === EDIT_KW_MODAL_TYPES.EDIT && keyword) {
			setOpen(true);
			setTitle("Edit keyword");
			setWord(keyword.word);
			setKeywordType(keyword.type);
			setParentKeyword(keyword.parentKeyWord);
		} else if (type === EDIT_KW_MODAL_TYPES.CREATE_CHILD && keyword) {
			setOpen(true);
			setTitle(`Create child keyword for ${keyword.word}`);
			setKeywordType(KEYWORD_TYPES.INDUSTRY_CATEGORIES);
			setParentKeyword(keyword);
		} else {
			setOpen(false);
			setWord("");
			setKeywordType("");
			setParentKeyword(null);
			onClose();
		}
	}, [type]);

	useEffect(() => {
		defKeywordType && setKeywordType(defKeywordType);
	}, [defKeywordType]);

	const createKeyword = async () => {
		setLoading(true);
		try {
			const payload = {
				keyWords: [
					{
						id: type === EDIT_KW_MODAL_TYPES.EDIT && keyword?.id ? keyword.id : undefined,
						word,
						type: keywordType,
						idClub: keyword?.idClub || clubId,
						parentKeyWord: parentKeyword,
					},
				],
			};
			const { status } = await API.post(`keyword`, payload);
			if (status === 200) {
				openSnackbar("Keyword updated successfully");
				onSave();
			} else {
				openSnackbar("Failed to update keyword");
			}
		} catch (error) {
			openSnackbar("Failed to update keyword");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			<Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="manage-members-modal">
				<Box sx={modalStyles}>
					<Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBottom: 2 }}>
						{title}
					</Typography>
					<Grid container spacing={2}>
						{parentKeyword && (
							<Grid item xs={12}>
								<TextField name="parentKeyword" label="Parent keyword" fullWidth variant="outlined" value={parentKeyword.word} disabled />
							</Grid>
						)}
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel id="chat-type-label">Select keyword type</InputLabel>
								<Select
									labelId="chat-type-label"
									id="chat-type-label"
									value={keywordType}
									label="Select keyword type"
									disabled={type === EDIT_KW_MODAL_TYPES.CREATE_CHILD}
									onChange={handleChangeKWType}
								>
									{Object.keys(KEYWORD_TYPES).map((key) => (
										<MenuItem key={key} value={key}>
											{KEYWORD_TYPES[key as keyof typeof KEYWORD_TYPES]}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<TextField name="word" label="Word" fullWidth variant="outlined" value={word} onChange={(event) => setWord(event.target.value)} />
						</Grid>
					</Grid>
					<div style={{ display: "flex", justifyContent: "flex-end", padding: "16px 0" }}>
						<Button variant="outlined" onClick={onClose} sx={{ mx: 1 }}>
							Cancel
						</Button>
						{loading ? (
							<LoadingButton loading variant="outlined" disabled sx={{ width: "68px" }}>
								<span>disabled</span>
							</LoadingButton>
						) : (
							<Button variant="contained" onClick={createKeyword} disabled={!word?.trim()?.length || !keywordType}>
								Save
							</Button>
						)}
					</div>
				</Box>
			</Modal>
		</div>
	);
}
