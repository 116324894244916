import { useRecoilState } from "recoil";
import { selfUserAtom } from "../atoms";
import { useState } from "react";
import { API } from "../queries/api";
import { ADMIN_ROLE, ADMIN_ROLES } from "../constants";

export default function useSelfUser() {
	const [selfUser, setSelfUser] = useRecoilState(selfUserAtom);
	const [authStatus, setAuthStatus] = useState<"PENDING" | "FULLFILLED">(
		"PENDING"
	);
	const role = selfUser?.role;
	const status = selfUser?.status;
	const hasAdminPermission =
		ADMIN_ROLES.includes(role ?? "") && status === "ACTIVE";
	const hasClubLevelAccess =
		role === ADMIN_ROLE.CLUB_ADMIN || role === ADMIN_ROLE.CLUB_MODERATOR;
	const isClubAdmin = role === ADMIN_ROLE.CLUB_ADMIN;
	const isClubModerator = role === ADMIN_ROLE.CLUB_MODERATOR;
	const isSuperAdmin = role === ADMIN_ROLE.ADMIN;
	const clubId = selfUser?.idClub;

	const getSelfUser = async () => {
		try {
			const { data, status } = await API.get(`user`);
			if (status === 200 && !!data) {
				setSelfUser(data);
			} else {
				setSelfUser(null);
			}
		} catch (error) {
			console.log(error);
			setSelfUser(null);
		}
		setAuthStatus("FULLFILLED");
	};

	return {
		selfUser,
		role,
		hasAdminPermission,
		isClubAdmin,
		isSuperAdmin,
		clubId,
		authStatus,
		hasClubLevelAccess,
		isClubModerator,
		getSelfUser,
	};
}
