import App from "./App";
import "./styles/index.scss";

type GetstreamChatType = {
	id: string;
};

export default function GetstreamChat({ id }: GetstreamChatType) {
	return <App chatId={id} />;
}
