import { useEffect } from "react";
import useSelfUser from "./useSelfUser";

export default function useUpdateFavicon() {
	const { selfUser, hasClubLevelAccess } = useSelfUser();

	useEffect(() => {
		if (hasClubLevelAccess && selfUser?.club?.icon && selfUser?.club?.name) {
			let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
			if (!link) {
				link = document.createElement("link");
				link.rel = "icon";
				document.getElementsByTagName("head")[0].appendChild(link);
			}

			link.href = selfUser.club.icon;
			document.title = selfUser.club.name;
		}
	}, [selfUser, hasClubLevelAccess]);
}
