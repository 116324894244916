export function mapColors(type: string): string {
	switch (type) {
		case "0":
			return "#FF3B30";
		case "1":
			return "#FF9500";
		case "2":
			return "#FFCC00";
		case "3":
			return "#04C759";
		case "4":
			return "#00C7BE";
		case "5":
			return "#30B0C7";
		case "6":
			return "#32ADE6";
		case "7":
			return "#007AFF";
		case "8":
			return "#5856D6";
		case "9":
			return "#643173";
		case "10":
			return "#AF52DE";
		case "11":
			return "#FF2D55";
		default:
			return "#FF3B30";
	}
}
