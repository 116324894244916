import { Button, Typography } from "@mui/material";
import { CompanyType, KeywordType } from "../../types";
import Company from "./Company";
import ps from "./css/companies.module.scss";
import cn from "classnames";
import { KEYWORD_TYPES } from "../../constants";
import { API } from "../../queries/api";
import { useEffect, useState } from "react";

interface CompanyProps {
	companies: CompanyType[];
	idClub: string;
	onNewCompanyCreated?: (company: CompanyType) => void;
}

const initialCompany: CompanyType = {
	name: "",
	image: "",
	description: "",
	mainOffice: "",
	website: "",
	contacts: [
		{
			email: "",
			firstName: "",
			lastName: "",
			phoneNumber: "",
			position: "",
		},
	],
	industries: [],
	industryCategories: [],
	providesServices: [],
};

export default function Companies({
	companies,
	idClub,
	onNewCompanyCreated,
}: CompanyProps) {
	const [industries, setIndustries] = useState<KeywordType[]>([]);
	const [industryCategories, setIndustryCategories] = useState<KeywordType[]>(
		[]
	);
	const [companyRoles, setCompanyRoles] = useState<KeywordType[]>([]);
	const [isNewCompanyOpen, setIsNewCompanyOpen] = useState(false);

	useEffect(() => {
		getIndustries();
		getIndustryCategories();
		getCompanyRoles();
	}, []);

	const getIndustries = async () => {
		try {
			const { data, status } = await API.get(
				`keyword/${KEYWORD_TYPES.INDUSTRY}?idClub=${idClub}`
			);
			if (status === 200 && !!data.length) {
				setIndustries(data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getIndustryCategories = async () => {
		try {
			const { data, status } = await API.get(
				`keyword/${KEYWORD_TYPES.INDUSTRY_CATEGORIES}?idClub=${idClub}`
			);
			if (status === 200 && !!data.length) {
				setIndustryCategories(data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getCompanyRoles = async () => {
		try {
			const { data, status } = await API.get(
				`keyword/${KEYWORD_TYPES.COMPANY_ROLE}?idClub=${idClub}`
			);
			if (status === 200 && !!data.length) {
				setCompanyRoles(data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const newCompanyCreatedHandle = (company: CompanyType) => {
		setIsNewCompanyOpen(false);
		onNewCompanyCreated && onNewCompanyCreated(company);
	};

	return (
		<div className={cn(ps.companies)}>
			{Array.isArray(companies) && companies.length > 0 ? (
				<div>
					<Typography variant="h6">Companies:</Typography>
					{companies.map((company) => (
						<Company
							key={company.id}
							company={company}
							companyRoles={companyRoles}
							industriesOptions={industries}
							industryCategoriesOptions={industryCategories}
						/>
					))}
				</div>
			) : (
				<div>
					{isNewCompanyOpen ? (
						<Typography variant="h6">Add new company:</Typography>
					) : (
						<Typography variant="h6">No companies</Typography>
					)}
				</div>
			)}
			{onNewCompanyCreated &&
				(isNewCompanyOpen ? (
					<>
						<Company
							company={initialCompany}
							companyRoles={companyRoles}
							industriesOptions={industries}
							industryCategoriesOptions={industryCategories}
							onNewCompanyCreated={newCompanyCreatedHandle}
						/>
						<Typography variant="subtitle2">
							Please note: after saving the new company, all unsaved user's
							changes will also be saved. Please make sure you complete all
							changes to the current user before saving of a new company!
						</Typography>
					</>
				) : (
					<Button
						type="button"
						variant="contained"
						color="primary"
						onClick={() => setIsNewCompanyOpen(true)}
					>
						Add company
					</Button>
				))}
		</div>
	);
}
