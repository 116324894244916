import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import { LibraryItemType } from "../../types";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import LibraryItemFile from "./LibraryItemFile";

interface LibraryPropType {
	item: LibraryItemType;
	onApprove?: (id: string) => void;
	onReject?: (id: string) => void;
}

const defaultFromUser = {
	firstName: "Deleted",
	lastName: "User",
	imageLink: "",
};

export default function LibraryItem({ item, onApprove, onReject }: LibraryPropType) {
	const { idSuggestion, created, text, title, parts, fromUser } = item;
	const isPending = !!onApprove && !!onReject && !!idSuggestion;
	const { firstName, lastName, imageLink: userImage } = fromUser || defaultFromUser;

	return (
		<Card sx={{ maxWidth: 345 }}>
			<CardHeader
				avatar={<Avatar src={userImage} sx={{ bgcolor: red[500] }} aria-label="recipe" />}
				// action={
				// 	<IconButton aria-label="settings">
				// 		<MoreVertIcon />
				// 	</IconButton>
				// }
				title={`${firstName} ${lastName}`}
				subheader={dayjs(created).format("DD/MMMM/YYYY")}
			/>
			{!!parts?.length &&
				parts.map((part) => {
					return <LibraryItemFile part={part} key={part.id} />;
				})}
			<CardContent>
				{!!title && (
					<Typography gutterBottom variant="h5" component="div">
						{title}
					</Typography>
				)}
				{!!text && (
					<Typography variant="body2" color="text.secondary">
						{text}
					</Typography>
				)}
			</CardContent>
			{isPending && (
				<CardActions sx={{ justifyContent: "end" }}>
					<Button size="small" color="success" variant="outlined" onClick={() => onApprove(idSuggestion)}>
						Approve
					</Button>
					<Button size="small" color="error" variant="outlined" onClick={() => onReject(idSuggestion)}>
						Reject
					</Button>
				</CardActions>
			)}
		</Card>
	);
}
