import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { API } from "../../queries/api";
import ImageUpload from "../../components/image-upload/ImageUpload";
import { Avatar } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import useDebounce from "../../hooks/useDebounce";
import { InterestType, InterestUsersType, UserType } from "../../types";
import { CHAT_TYPES, IMAGE_TYPES } from "../../constants";
import useSnackbar from "../../hooks/useSnackbar";
import AddUsersByInterestsModal from "./AddUsersByInterestsModal";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CreateChat() {
	const [loading, setLoading] = useState(false);
	const { clubId } = useParams<{ clubId: string }>();
	const [image, setImage] = useState<string | null>(null);
	const [chatName, setChatName] = useState<string>("");
	const [chatDescription, setChatDescription] = useState<string>("");
	const [snackbarMsg, setSnackbarMsg] = useState("");
	const [includeAll, setIncludeAll] = useState(true);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [searchResults, setSearchResults] = useState<UserType[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<UserType[]>([]);
	const [isUserSearchLoading, setIsUserSearchLoading] = useState(false);
	const [interests, setInterests] = useState<InterestType[]>([]);
	const [selectedInterests, setSelectedInterests] = useState<InterestType[]>([]);
	const [searchInterestsQuery, setSearchInterestsQuery] = useState<string>("");
	const [isInterestsTypeaheadOpen, setIsInterestsTypeaheadOpen] = useState(false);
	const [isInterestsSearchLoading, setIsInterestsSearchLoading] = useState(false);
	const [chatType, setChatType] = useState<string>(CHAT_TYPES.CLUB.value);
	const [interestsWithUsers, setInterestWithUsers] = useState<InterestUsersType[]>([]);
	const [isAddUsersByInterestsModalOpen, setIsAddUsersByInterestsModalOpen] = useState(false);
	const navigate = useNavigate();
	const openSnackbar = useSnackbar();

	const handleCloseModal = () => {
		setIsAddUsersByInterestsModalOpen(false);
	};

	const getInterests = async () => {
		setIsInterestsSearchLoading(true);
		try {
			const { data, status } = await API.get(`keyword/PERSONAL_INTERESTS`);
			if (status === 200 && !!data.length) {
				setInterests(data);
			}
		} catch (error) {
			console.log(error);
		}
		setIsInterestsSearchLoading(false);
	};

	useEffect(() => {
		getInterests();
	}, []);

	const saveChat = async (usersByInterests: UserType[] | undefined = []) => {
		try {
			setLoading(true);
			const allMembers = [...usersByInterests, ...selectedUsers];
			const memberIDs = Array.from(new Set(allMembers.map((user) => user.id)));
			const payload = {
				clubId: clubId,
				name: chatName,
				description: chatDescription,
				image: image,
				memberIDs: memberIDs.length ? memberIDs : undefined,
				includeAll: includeAll,
				chatPurpose: chatType,
			};
			const { data, status } = await API.post(`getstream/club_chat`, payload);
			if (status === 200 && !!data) {
				openSnackbar("Chat created successfully");
				setSnackbarMsg("Chat created successfully");
				navigate(`/club`);
			} else {
				setSnackbarMsg("Error creating chat");
			}
		} catch (error) {
			console.log(error);
			setSnackbarMsg("Error creating chat");
		}
		setLoading(false);
	};

	const imageUploadHandle = (imageLink: string) => {
		setImage(imageLink);
	};

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackbarMsg("");
	};

	const includeAllHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIncludeAll(event.target.checked);
	};

	const [isUsersTypeaheadOpen, setIsUsersTypeaheadOpen] = useState(false);

	const debouncedSearchTerm = useDebounce(searchQuery, 500);

	useEffect(() => {
		if (debouncedSearchTerm.trim().length > 0) {
			searchUsers(debouncedSearchTerm);
		}
	}, [debouncedSearchTerm]);

	const searchUsers = async (query: string) => {
		try {
			setIsUserSearchLoading(true);
			const payload = {
				startDate: "2020-10-03T20:42:30.519Z",
				endDate: "2029-10-03T20:42:30.519Z",
				page: 0,
				size: 20,
				reverse: true,
			};
			const { data, status } = await API.post(`users/search?searchStr=${query}&idClub=${clubId}`, payload);
			const users = data?.items;
			if (status === 200 && !!users.length) {
				setSearchResults(data?.items);
			} else {
				setSearchResults([]);
			}
		} catch (error) {
			console.log(error);
			setSearchResults([]);
		}
		setIsUserSearchLoading(false);
	};

	const getUsersByInterests = async () => {
		try {
			const { data, status } = await API.post(`users/interests`, {
				page: 0,
				size: 10000,
				interests: selectedInterests.map((interest) => interest.id),
				idClub: clubId,
			});
			if (status === 200 && !!data.items.length) {
				const interestsWithUsers = selectedInterests.reduce((acc: any, interest: InterestType) => {
					const users = data.items.filter((user: UserType) => {
						const userInterestsIds = user.interests.map((interest: any) => interest.id);
						return userInterestsIds.includes(interest.id);
					});
					const interestWithUsers = { ...interest, users };
					return [...acc, interestWithUsers];
				}, []);

				setInterestWithUsers(interestsWithUsers);
				setIsAddUsersByInterestsModalOpen(true);
			} else {
				setSnackbarMsg("Error creating chat. No users found with selected interests. Please select users manually or try different interests.");
			}
		} catch (error) {
			console.log(error);
			setSnackbarMsg("Error creating chat. No users found with selected interests. Please select users manually or try different interests.");
		}
	};

	const submitHandle = () => {
		saveChat();
	};

	const handleChangeChatType = (event: SelectChangeEvent) => {
		setChatType(event.target.value as string);
	};

	const selectUsersByInterestsHandle = (users: UserType[]) => {
		const newSelectedUsers = [...selectedUsers, ...users];
		const uniqueSelectedUsers = Array.from(new Set(newSelectedUsers.map((user) => user.id))).map((id) => newSelectedUsers.find((user) => user.id === id));
		setSelectedUsers(uniqueSelectedUsers as UserType[]);
	};

	React.useEffect(() => {
		if (!isUsersTypeaheadOpen) {
			setSearchResults([]);
		}
	}, [isUsersTypeaheadOpen]);

	return (
		<Paper
			sx={{
				p: 2,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography component="h2" variant="h6" color="primary" gutterBottom>
				Create Chat
			</Typography>
			{loading && (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			)}
			<ImageUpload onUpload={imageUploadHandle} type={IMAGE_TYPES.CHAT_PHOTO} />
			{image && <Avatar alt="Remy Sharp" src={image} sx={{ width: 100, height: 100, marginBottom: "16px" }} />}
			<Grid container spacing={2} sx={{ marginBottom: "8px" }}>
				<Grid item xs={4}>
					<TextField name="chat-name" label="Chat Name" fullWidth variant="outlined" value={chatName} onChange={(event) => setChatName(event.target.value)} />
				</Grid>
				<Grid item xs={8}>
					<TextField name="description" label="Description" fullWidth multiline variant="outlined" value={chatDescription} onChange={(event) => setChatDescription(event.target.value)} />
				</Grid>
				<Grid item xs={6}>
					<FormControl fullWidth>
						<InputLabel id="chat-type-label">Select channel type</InputLabel>
						<Select labelId="chat-type-label" id="chat-type-label" value={chatType} label="Select channel type" onChange={handleChangeChatType}>
							{Object.keys(CHAT_TYPES).map((key) => (
								<MenuItem key={key} value={CHAT_TYPES[key as keyof typeof CHAT_TYPES].value}>
									{CHAT_TYPES[key as keyof typeof CHAT_TYPES].label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			<FormControlLabel control={<Switch color="info" onChange={includeAllHandle} checked={includeAll} />} label="Add all club users to this chat" />
			{!includeAll && (
				<div>
					<Typography variant="h6" gutterBottom>
						Add Users by Name
					</Typography>
					<Autocomplete
						multiple
						sx={{ my: 2 }}
						open={isUsersTypeaheadOpen}
						onOpen={() => {
							setIsUsersTypeaheadOpen(true);
						}}
						onClose={() => {
							setIsUsersTypeaheadOpen(false);
						}}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
						options={searchResults}
						loading={isUserSearchLoading}
						value={selectedUsers}
						onChange={(event, newValue) => {
							setSelectedUsers(newValue);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Search Users"
								value={searchQuery}
								onChange={(event) => setSearchQuery(event.target.value)}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{isUserSearchLoading ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
					<Typography variant="h6" gutterBottom>
						Add users by Interests
					</Typography>
					<div style={{ display: "flex", alignItems: "center" }}>
						<Autocomplete
							multiple
							fullWidth
							sx={{ my: 2 }}
							open={isInterestsTypeaheadOpen}
							onOpen={() => {
								setIsInterestsTypeaheadOpen(true);
							}}
							onClose={() => {
								setIsInterestsTypeaheadOpen(false);
							}}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							getOptionLabel={(option) => option.word || ""}
							options={interests}
							loading={isInterestsSearchLoading}
							value={selectedInterests}
							onChange={(event, newValue) => {
								setSelectedInterests(newValue);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Search Interests"
									value={searchInterestsQuery}
									onChange={(event) => setSearchInterestsQuery(event.target.value)}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{isUserSearchLoading ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
						/>
						<Button variant="contained" color="info" onClick={getUsersByInterests} sx={{ margin: "0 12px", width: "160px" }}>
							Apply
						</Button>
					</div>
				</div>
			)}
			<div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "16px" }}>
				<Button variant="contained" color="primary" onClick={submitHandle}>
					Create chat
				</Button>
			</div>
			<Snackbar open={!!snackbarMsg} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
					{snackbarMsg}
				</Alert>
			</Snackbar>
			<AddUsersByInterestsModal isOpen={isAddUsersByInterestsModalOpen} interests={interestsWithUsers} onClose={handleCloseModal} onConfirm={selectUsersByInterestsHandle} />
		</Paper>
	);
}
