import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { confirmModalAtom } from "../../atoms";
import { useRecoilState } from "recoil";

export default function ConfirmModal() {
	const [modalState, setModalState] = useRecoilState(confirmModalAtom);
	const [open, setOpen] = useState(modalState.open);

	const handleCloseModal = () => {
		setModalState({ ...modalState, open: false });
	};

	const confirmHandle = () => {
		handleCloseModal();
		modalState.onConfirm();
	};

	useEffect(() => {
		setOpen(modalState.open);
	}, [modalState.open]);

	return (
		<Dialog open={open} onClose={handleCloseModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{modalState.title}</DialogTitle>
			<DialogActions>
				<Button onClick={handleCloseModal} variant="outlined">
					Cancel
				</Button>
				<Button onClick={confirmHandle} variant="contained" color="error">
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
