import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { API } from "../../queries/api";
import ImageUpload from "../../components/image-upload/ImageUpload";
import { Avatar } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { IMAGE_TYPES } from "../../constants";
import { useNavigate } from "react-router-dom";
import useSnackbar from "../../hooks/useSnackbar";
import FileUpload from "../../components/file-upload/FileUpload";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CreateClub() {
	const [loading, setLoading] = useState(false);
	const [image, setImage] = useState<string | null>(null);
	const [name, setName] = useState<string>("");
	const [code, setCode] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [rulesFile, setRulesFile] = useState<string | null>(null);
	const [snackbarMsg, setSnackbarMsg] = useState("");
	const [isPublic, setIsPublic] = useState<boolean>(true);
	const navigate = useNavigate();
	const openSnackbar = useSnackbar();

	const isPublicHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsPublic(event.target.checked);
	};

	const saveClub = async () => {
		if (!name) {
			setSnackbarMsg("Name is required");
			return;
		}
		if (!code || code.length !== 6) {
			setSnackbarMsg("Code is required, 6 characters long");
			return;
		}
		if (!email) {
			setSnackbarMsg("Email is required");
			return;
		}
		setLoading(true);
		try {
			const payload = {
				name,
				code: code.toUpperCase(),
				icon: image,
				email,
				rulesFile,
				publicAccess: isPublic,
			};
			const { data, status } = await API.post(`club`, payload);
			if (status === 200 && !!data) {
				openSnackbar("Club created successfully");
				setSnackbarMsg("Club created successfully");
				navigate(`/clubs`);
			} else {
				setSnackbarMsg("Error creating chat");
			}
		} catch (error) {
			console.log(error);
			setSnackbarMsg("Error creating chat");
		}
		setLoading(false);
	};

	const imageUploadHandle = (imageLink: string) => {
		setImage(imageLink);
	};

	const rulesUploadHandle = (fileLink: string) => {
		setRulesFile(fileLink);
	};

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackbarMsg("");
	};

	const generateCode = () => {
		// generate code 6 characters long with capital letters and numbers
		const code = Math.random().toString(36).substr(2, 6).toUpperCase();
		setCode(code);
	};

	return (
		<Paper
			sx={{
				p: 2,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography component="h2" variant="h6" color="primary" gutterBottom>
				Create Club
			</Typography>
			{loading && (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			)}
			<div style={{ display: "flex" }}>
				<ImageUpload onUpload={imageUploadHandle} type={IMAGE_TYPES.CLUB_PHOTO} />
				<FileUpload onUpload={rulesUploadHandle} type="FILE" />
			</div>
			{image && <Avatar alt="Remy Sharp" src={image} sx={{ width: 100, height: 100 }} />}
			<FormControlLabel sx={{ marginBottom: "8px" }} control={<Switch color="info" onChange={isPublicHandle} checked={isPublic} />} label="Make club public" />
			<Grid container spacing={2} sx={{ marginBottom: "8px" }} alignItems="center">
				<Grid item xs={2}>
					<TextField name="code" label="Code" fullWidth multiline variant="outlined" value={code} onChange={(event) => setCode(event.target.value)} inputProps={{ maxLength: 6 }} />
				</Grid>
				<Grid item>
					<Button variant="contained" color="primary" onClick={generateCode}>
						Generate Code
					</Button>
				</Grid>
			</Grid>
			<Grid container spacing={2} sx={{ marginBottom: "8px" }}>
				<Grid item xs={4}>
					<TextField name="name" label="Name" fullWidth variant="outlined" value={name} onChange={(event) => setName(event.target.value)} />
				</Grid>
				<Grid item xs={5}>
					<TextField name="email" label="Corporate Email" fullWidth variant="outlined" value={email} onChange={(event) => setEmail(event.target.value)} />
				</Grid>
			</Grid>

			<div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "16px" }}>
				<Button variant="contained" color="primary" onClick={saveClub}>
					Create club
				</Button>
			</div>
			<Snackbar open={!!snackbarMsg} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
					{snackbarMsg}
				</Alert>
			</Snackbar>
		</Paper>
	);
}
