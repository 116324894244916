import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { sideNavigationItemsAdmin, sideNavigationItemsClubAdmin, sideNavigationItemsClubModerator } from "../../constants/sideNavigationItems";
import { SideNavigationItem } from "../../types";
import useSelfUser from "../../hooks/useSelfUser";

interface NavigationListType {
	list: Array<SideNavigationItem>;
}

const NavigationList = ({ list }: NavigationListType): any => {
	return list.map((item) => {
		const { name, icon, link } = item;
		return (
			<Link to={link} key={name}>
				<ListItemButton>
					<ListItemIcon sx={{ minWidth: "40px" }}>{icon}</ListItemIcon>
					<ListItemText primary={name} />
				</ListItemButton>
			</Link>
		);
	});
};

const SideNavigation = (): any => {
	const { isSuperAdmin, isClubAdmin, isClubModerator } = useSelfUser();
	const sideNavigationItems = (() => {
		if (isSuperAdmin) {
			return sideNavigationItemsAdmin;
		}
		if (isClubAdmin) {
			return sideNavigationItemsClubAdmin;
		}
		if (isClubModerator) {
			return sideNavigationItemsClubModerator;
		}
		return [];
	})();

	return (
		<>
			<NavigationList list={sideNavigationItems} />
		</>
	);
};

// {/* <Divider sx={{ my: 1 }} /> */}
// {/* {secondaryListItems} */}

export default SideNavigation;
