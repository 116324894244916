import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useMatches } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import useSelfUser from "../../hooks/useSelfUser";

export default function PrivateRoute() {
	const location = useLocation();
	const { getSelfUser, hasAdminPermission, authStatus, role: userRole } = useSelfUser();
	const matches = useMatches();

	const deepestRouteWithAccessRoles: any = matches
		.map((match) => match.handle)
		.reverse()
		.find((handle) => (handle as { accessRoles?: string[] })?.accessRoles);
	const hasRequiredRole = !deepestRouteWithAccessRoles?.accessRoles?.includes(userRole);
	// if no role specified in router, then all admins have access. Otherwise, check if user has required role;
	const hasPageAccess = deepestRouteWithAccessRoles && hasRequiredRole;

	useEffect(() => {
		getSelfUser();
	}, []);

	if (authStatus === "PENDING") {
		return (
			<Box sx={{ width: "100%" }}>
				<LinearProgress />
			</Box>
		);
	}

	if (!hasAdminPermission) {
		return <Navigate to="/login" replace state={{ from: location }} />;
	}

	if (hasPageAccess) {
		return <>You don't have access to this page</>;
	}

	return <Outlet />;
}
