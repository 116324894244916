import { IconButton, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DifferenceIcon from "@mui/icons-material/Difference";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { KeywordType } from "../../types";
import { KEYWORD_TYPES } from "../../constants";
import useConfirmModal from "../../hooks/useConfirmModal";
import ReactVirtualizedTable from "../../components/virtualized-table/VirtualizedTable";
import { useMemo } from "react";
import { ColumnData } from "../../components/virtualized-table/types";

// max, padding outer, padding inner, header, picker
const tableHeight = "calc(99vh - 64px - 64px - 32px - 72px)";

type KWTableType = {
	KWType: string;
	keywords: Array<KeywordType>;
	onDelete: (id: string) => void;
	onEdit: (keyword: KeywordType) => void;
	onUseAsParent: (keyword: KeywordType) => void;
	onOpenChild: (keyword: KeywordType) => void;
};

export default function KWTable({
	keywords,
	KWType,
	onDelete,
	onEdit,
	onUseAsParent,
	onOpenChild,
}: KWTableType) {
	const { openConfirmModal } = useConfirmModal({
		onConfirm: ({ itemData }) => {
			onDelete(itemData.id);
		},
	});
	const isParentRowShown = KWType === KEYWORD_TYPES.INDUSTRY_CATEGORIES;
	const canBeParent = KWType === KEYWORD_TYPES.INDUSTRY;

	const rows = useMemo(() => {
		return keywords.map((row) => {
			return {
				word: row.word,
				parentKeyWord: isParentRowShown
					? row.parentKeyWord?.word || "-"
					: undefined,
				id: <Typography variant="caption">{row.id}</Typography>,
				actions: (
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Tooltip title="Delete" placement="top">
							<IconButton
								aria-label="delete"
								onClick={() =>
									openConfirmModal({
										itemData: row,
										title: `Are you sure you want to delete ${row.word} keyword?`,
									})
								}
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Edit" placement="top">
							<IconButton
								aria-label="edit"
								onClick={() => {
									onEdit(row);
								}}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
						{canBeParent && (
							<>
								<Tooltip title="Use as parent" placement="top">
									<IconButton
										aria-label="use as parent"
										onClick={() => {
											onUseAsParent(row);
										}}
									>
										<DifferenceIcon />
									</IconButton>
								</Tooltip>
								<Tooltip title="Show child categories" placement="top">
									<IconButton
										aria-label="Show child categories"
										onClick={() => {
											onOpenChild(row);
										}}
									>
										<OpenInNewIcon />
									</IconButton>
								</Tooltip>
							</>
						)}
					</div>
				),
			};
		});
	}, [keywords]);

	const columns = useMemo(() => {
		return [
			{
				label: "Word",
				dataKey: "word",
			},
			isParentRowShown && {
				label: "Parent Keyword",
				dataKey: "parentKeyWord",
			},
			{
				label: "",
				dataKey: "actions",
			},
		].filter(Boolean) as ColumnData[];
	}, [isParentRowShown]);

	return (
		<>
			<ReactVirtualizedTable
				columns={columns}
				rows={rows}
				height={tableHeight}
			/>
		</>
	);
}
