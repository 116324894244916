import { Button, Typography } from "@mui/material";
import { CompanyPeopleType, KeywordType } from "../../types";
import { useState } from "react";
import { List } from "@mui/material";
import AddUserToCompanyModal from "./AddUserToCompanyModal";
import PeopleItem from "./CompanyPeopleItem";

type CompanyPeopleProps = {
	people: CompanyPeopleType[] | undefined;
	companyRoles: KeywordType[];
};

export default function CompanyPeople({
	people,
	companyRoles,
}: CompanyPeopleProps) {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	if (!people) return null;

	return (
		<div>
			<Typography variant="h6">People:</Typography>
			{!!people.length &&
				people.map((peopleItem) => (
					<List sx={{ width: "100%", maxWidth: 400 }}>
						<PeopleItem
							key={peopleItem.id}
							person={peopleItem}
							companyRoles={companyRoles}
						/>
					</List>
				))}
			<br />
			<Button
				type="button"
				variant="contained"
				color="info"
				onClick={handleOpenModal}
			>
				Add people
			</Button>
			{isModalOpen && (
				<AddUserToCompanyModal
					companyRoles={companyRoles}
					isOpen
					onClose={handleCloseModal}
				/>
			)}
		</div>
	);
}
