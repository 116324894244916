import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useState, useEffect } from "react";
import { API } from "../../queries/api";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { EDIT_KW_MODAL_TYPES, KEYWORD_TYPES } from "../../constants";
import useSnackbar from "../../hooks/useSnackbar";
import { Button } from "@mui/material";
import EditKWModal from "./EditKWModal";
import { KeywordType } from "../../types";
import KWTable from "./KWTable";
import TextField from "@mui/material/TextField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InputAdornment from "@mui/material/InputAdornment";
import useSelfUser from "../../hooks/useSelfUser";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { VisuallyHiddenInput } from "../../constants/hiddenIput";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import _ from "lodash";
import { CloudDownload } from "@mui/icons-material";

interface EditModalState {
	type: string;
	keyword?: KeywordType;
}

function transformAndStringifyNestedObjects(obj: any) {
	for (const key in obj) {
		if (typeof obj[key] === "object" && obj[key] !== null) {
			if (Array.isArray(obj[key])) {
				obj[key] = obj[key].map((item: any) =>
					transformAndStringifyNestedObjects(item)
				);
			} else {
				obj[key] = JSON.stringify(obj[key]);
			}
		}
	}
}

export default function Keywords() {
	const [loading, setLoading] = useState(false);
	const [keywordType, setKeywordType] = useState<string>(
		KEYWORD_TYPES.PERSONAL_INTERESTS
	);
	const [keywords, setKeywords] = useState<KeywordType[]>([]);
	const [editModalState, setEditModalState] = useState<EditModalState>({
		type: "",
	});
	const [filterWord, setFilterWord] = useState<string>("");
	const openSnackbar = useSnackbar();
	const isAddKWOptionAvailable =
		keywordType !== KEYWORD_TYPES.INDUSTRY_CATEGORIES;
	const { clubId, selfUser } = useSelfUser();
	const clubName = selfUser?.club?.name;

	const filterByName = (kw: KeywordType) => {
		// filter by word and parentKeyWord any match
		return (
			kw.word.toLowerCase().includes(filterWord.toLowerCase()) ||
			(kw.parentKeyWord &&
				kw.parentKeyWord.word.toLowerCase().includes(filterWord.toLowerCase()))
		);
	};

	const KWsToShow = filterWord ? keywords.filter(filterByName) : keywords;

	const handleChangeKWType = (event: SelectChangeEvent) => {
		setKeywordType(event.target.value as string);
		fetchKeywords(`keyword/${event.target.value}`);
	};

	const fetchKeywords = async (query: string = `keyword/${keywordType}`) => {
		setLoading(true);
		try {
			const url = `${query}?idClub=${clubId}`;
			const { data, status } = await API.get(url);
			if (status === 200 && Array.isArray(data) && data.length) {
				const sortedData = data.sort((a, b) => a.word.localeCompare(b.word));
				setKeywords(sortedData);
			} else if (status === 200 && Array.isArray(data) && !data.length) {
				setKeywords([]);
				openSnackbar("No keywords found");
			} else {
				openSnackbar("Error fetching keywords");
				setKeywords([]);
			}
		} catch (error) {
			console.error("Error fetching keywords:", error);
			openSnackbar("Error fetching keywords");
			setKeywords([]);
		}
		setLoading(false);
	};

	const KWSaveHandle = () => {
		fetchKeywords();
		setEditModalState({ type: "" });
	};

	const deleteKWHandle = async (id: string) => {
		try {
			const { status } = await API.delete(`keyword/${id}`);
			if (status === 200) {
				openSnackbar("Keyword deleted successfully");
				fetchKeywords();
			} else {
				openSnackbar("Failed to delete keyword");
			}
		} catch (error) {
			console.error("Error deleting keyword:", error);
			openSnackbar("Failed to delete keyword");
		}
	};

	const openChildHandle = async (keyword: KeywordType) => {
		setKeywordType(KEYWORD_TYPES.INDUSTRY_CATEGORIES);
		fetchKeywords(`keyword/${keyword.id}/categories`);
	};

	useEffect(() => {
		fetchKeywords();
	}, []);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const binaryString = e.target?.result as string;
				const workbook = XLSX.read(binaryString, { type: "binary" });
				const sheetName = workbook.SheetNames[0]; // Assume only one sheet for simplicity
				const worksheet = workbook.Sheets[sheetName];
				const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

				// Extract headers (first row) from the sheet
				const headers: any = data[0];

				// Filter out empty rows (rows with undefined or empty values)
				const parsedData = data
					.slice(1)
					.filter((row: any) => {
						// Check if all values in the row are undefined or empty
						return Object.values(row).some(
							(value) => value !== undefined && value !== ""
						);
					})
					.map((row: any) => {
						const parsedRow: any = {};
						headers.forEach((header: string, index: number) => {
							let parsedValue = row[index] === undefined ? null : row[index];

							// Parse JSON stringified objects back to objects
							try {
								if (typeof parsedValue === "string") {
									parsedValue = JSON.parse(parsedValue);
								}
							} catch (error) {
								// console.error("Error parsing JSON string:", error);
							}

							parsedRow[header] = parsedValue;
						});
						return parsedRow;
					});
				updateKeywords(parsedData as KeywordType[]);
			};
			reader.readAsBinaryString(file);
			// @ts-ignore
			event.target.value = null;
		}
	};

	const updateKeywords = async (data: KeywordType[]) => {
		setLoading(true);
		try {
			const payload = {
				keyWords: data,
			};
			const { status } = await API.post(`keyword`, payload);
			if (status === 200) {
				openSnackbar("Keywords updated successfully");
				fetchKeywords();
			} else {
				openSnackbar("Failed to update keywords");
			}
		} catch (error) {
			openSnackbar("Failed to update keywords");
		} finally {
			setLoading(false);
		}
	};

	const generateExcelData = () => {
		const updatedData = keywords.map((row) => {
			// Create a copy of the row to avoid mutating the original data
			const processedRow = _.cloneDeep(row);
			transformAndStringifyNestedObjects(processedRow);

			return processedRow;
		});

		const worksheet = XLSX.utils.json_to_sheet(updatedData);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, keywordType);
		const excelBuffer = XLSX.write(workbook, {
			bookType: "xlsx",
			type: "array",
		});
		saveAs(new Blob([excelBuffer]), `${keywordType}-${clubName}.xlsx`);
	};

	return (
		<Paper
			sx={{
				p: 2,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography component="h2" variant="h6" color="primary" gutterBottom>
				Keywords
			</Typography>
			{loading && (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			)}
			<Grid
				container
				sx={{ mb: 1 }}
				alignItems="center"
				justifyContent="space-between"
			>
				<Grid item>
					<Grid container spacing={2}>
						<Grid item>
							<FormControl sx={{ width: "240px" }}>
								<InputLabel id="chat-type-label">
									Select keyword type
								</InputLabel>
								<Select
									labelId="chat-type-label"
									id="chat-type-label"
									value={keywordType}
									label="Select keyword type"
									onChange={handleChangeKWType}
								>
									{Object.keys(KEYWORD_TYPES).map((key) => (
										<MenuItem key={key} value={key}>
											{KEYWORD_TYPES[key as keyof typeof KEYWORD_TYPES]}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							<TextField
								sx={{ width: "300px" }}
								label="Filter by word"
								variant="outlined"
								value={filterWord}
								onChange={(event) => setFilterWord(event.target.value)}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<FilterAltIcon />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<div style={{ display: "flex", gap: "8px" }}>
						<Button
							style={{ marginLeft: "auto" }}
							variant="contained"
							color="success"
							onClick={generateExcelData}
							startIcon={<CloudDownload />}
						>
							Download Excel
						</Button>
						<Button
							component="label"
							variant="contained"
							color="info"
							startIcon={<CloudUploadIcon />}
						>
							Upload Excel
							<VisuallyHiddenInput type="file" onChange={handleFileChange} />
						</Button>
						{isAddKWOptionAvailable && (
							<Button
								style={{ marginLeft: "auto" }}
								variant="contained"
								color="primary"
								onClick={() => {
									setEditModalState({ type: EDIT_KW_MODAL_TYPES.NEW });
								}}
							>
								Add New Keyword
							</Button>
						)}
					</div>
				</Grid>
			</Grid>
			<KWTable
				keywords={KWsToShow}
				KWType={keywordType}
				onDelete={deleteKWHandle}
				onEdit={(keyword: KeywordType) => {
					setEditModalState({ type: EDIT_KW_MODAL_TYPES.EDIT, keyword });
				}}
				onUseAsParent={(keyword: KeywordType) => {
					setEditModalState({
						type: EDIT_KW_MODAL_TYPES.CREATE_CHILD,
						keyword,
					});
				}}
				onOpenChild={openChildHandle}
			/>
			<EditKWModal
				state={editModalState}
				defKeywordType={keywordType}
				onClose={() => setEditModalState({ type: "" })}
				onSave={KWSaveHandle}
			/>
		</Paper>
	);
}
