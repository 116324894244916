import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { API } from "../../queries/api";
import ImageUpload from "../../components/image-upload/ImageUpload";
import { Avatar } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { IMAGE_TYPES } from "../../constants";
import { useNavigate, useParams } from "react-router-dom";
import useSnackbar from "../../hooks/useSnackbar";
import FileUpload from "../../components/file-upload/FileUpload";
import { CloudDownload } from "@mui/icons-material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import useSelfUser from "../../hooks/useSelfUser";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function EditClub() {
	const [loading, setLoading] = useState(false);
	const [image, setImage] = useState<string | null>(null);
	const [name, setName] = useState<string>("");
	const [code, setCode] = useState<string>("");
	const [snackbarMsg, setSnackbarMsg] = useState("");
	const { clubId } = useParams<{ clubId: string }>();
	const [email, setEmail] = useState<string>("");
	const [rulesFile, setRulesFile] = useState<string | null>(null);
	const [rulesLink, setRulesLink] = useState<string>("");
	const [isPublic, setIsPublic] = useState<boolean>(false);
	const { isSuperAdmin } = useSelfUser();
	const navigate = useNavigate();
	const openSnackbar = useSnackbar();

	useEffect(() => {
		getClub();
	}, []);

	const isPublicHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsPublic(event.target.checked);
	};

	const rulesUploadHandle = (fileLink: string) => {
		setRulesFile(fileLink);
	};

	const getClub = async () => {
		try {
			setLoading(true);
			const { data, status } = await API.get(`club/${clubId}`);
			if (status === 200 && !!data) {
				setName(data.name);
				setImage(data.icon);
				setCode(data.code);
				setEmail(data.email);
				setRulesLink(data.rulesFile);
				setIsPublic(data.publicAccess);
			} else {
				// setClub(null);
			}
		} catch (error) {
			console.log(error);
			// setClub(null);
		}
		setLoading(false);
	};

	const saveClub = async () => {
		if (!name) {
			setSnackbarMsg("Name is required");
			return;
		}
		if (!code || code.length !== 6) {
			setSnackbarMsg("Code is required, 6 characters long");
			return;
		}
		setLoading(true);
		try {
			const payload = {
				id: clubId,
				code: code.toUpperCase(),
				name,
				icon: image,
				email,
				rulesFile,
				publicAccess: isPublic,
			};
			const { data, status } = await API.put(`club`, payload);
			if (status === 200 && !!data) {
				openSnackbar("Club saved successfully");
				setSnackbarMsg("Club saved successfully");
				const clubLink = isSuperAdmin ? `/club/${clubId}` : "/club";
				navigate(clubLink);
			} else {
				setSnackbarMsg("Error saving chat");
			}
		} catch (error) {
			console.log(error);
			setSnackbarMsg("Error saving chat");
		}
		setLoading(false);
	};

	const imageUploadHandle = (imageLink: string) => {
		setImage(imageLink);
	};

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarMsg("");
	};

	return (
		<Paper
			sx={{
				p: 2,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Typography component="h2" variant="h6" color="primary" gutterBottom>
				Edit Club
			</Typography>
			{loading && (
				<Box sx={{ width: "100%", my: 0.5 }}>
					<LinearProgress />
				</Box>
			)}
			<div style={{ display: "flex" }}>
				<ImageUpload onUpload={imageUploadHandle} type={IMAGE_TYPES.CLUB_PHOTO} />
				<FileUpload onUpload={rulesUploadHandle} type="FILE" />
				{!!rulesLink && (
					<div style={{ display: "flex", marginLeft: "16px", justifyContent: "flex-start", padding: "16px 0", alignItems: "center" }}>
						<Button size="medium" variant="contained" component="label" color="info" href={rulesLink} startIcon={<CloudDownload />}>
							Download current rules
						</Button>
					</div>
				)}
			</div>
			{image && <Avatar alt="Remy Sharp" src={image} sx={{ width: 100, height: 100 }} />}
			<FormControlLabel sx={{ marginBottom: "8px" }} control={<Switch color="info" onChange={isPublicHandle} checked={isPublic} />} label="Is club public" />
			{/* <Grid container spacing={2} sx={{ marginBottom: "8px" }}>
				<Grid item xs={4}>
					<TextField name="name" label="Name" fullWidth variant="outlined" value={name} onChange={(event) => setName(event.target.value)} />
				</Grid>
				<Grid item xs={4}>
					<TextField name="code" label="Code" fullWidth multiline variant="outlined" value={code} disabled />
				</Grid>
			</Grid> */}
			<Grid container spacing={2} sx={{ marginBottom: "16px" }} alignItems="center">
				<Grid item xs={2}>
					<TextField name="code" label="Code" fullWidth multiline variant="outlined" value={code} onChange={(event) => setCode(event.target.value)} inputProps={{ maxLength: 6 }} />
				</Grid>
			</Grid>
			<Grid container spacing={2} sx={{ marginBottom: "8px" }}>
				<Grid item xs={4}>
					<TextField name="name" label="Name" fullWidth variant="outlined" value={name} onChange={(event) => setName(event.target.value)} />
				</Grid>
				<Grid item xs={5}>
					<TextField name="email" label="Corporate Email" fullWidth variant="outlined" value={email} onChange={(event) => setEmail(event.target.value)} />
				</Grid>
			</Grid>

			<div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "16px" }}>
				<Button variant="contained" color="primary" onClick={saveClub}>
					Save
				</Button>
			</div>
			<Snackbar open={!!snackbarMsg} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
					{snackbarMsg}
				</Alert>
			</Snackbar>
		</Paper>
	);
}
