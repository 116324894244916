import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import type {} from "@mui/lab/themeAugmentation";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RecoilRoot } from "recoil";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import router from "./routes/router";

const theme = createTheme({
	palette: {
		primary: {
			main: "#0f0f0f",
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<RecoilRoot>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<RouterProvider router={router} />
				</LocalizationProvider>
			</RecoilRoot>
		</ThemeProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
