import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableComponents } from "react-virtuoso";
import { ColumnData } from "./types";

import type { ComponentPropsWithoutRef } from "react";
import { forwardRef } from "react";
import TableFooter from "@mui/material/TableFooter";

export const VirtuosoTableComponents: TableComponents = {
	TableFoot: forwardRef((props: ComponentPropsWithoutRef<"tfoot">, ref) => <TableFooter ref={ref} {...props} />),
	Scroller: forwardRef((props: ComponentPropsWithoutRef<"div">, ref) => <TableContainer ref={ref} component={Paper} {...props} />),
	Table,
	TableHead: forwardRef((props: ComponentPropsWithoutRef<"thead">, ref) => <TableHead ref={ref} {...props} />),
	TableRow,
	TableBody: forwardRef((props: ComponentPropsWithoutRef<"tbody">, ref) => <TableBody ref={ref} {...props} />),
};

export function fixedHeaderContent(columns: ColumnData[]) {
	return (
		<TableRow>
			{columns.map((column) =>
				!!column ? (
					<TableCell
						key={column.dataKey}
						variant="head"
						align={column.numeric || false ? "right" : "left"}
						style={{ width: column.width }}
						sx={{
							backgroundColor: "background.paper",
						}}
					>
						{column.label}
					</TableCell>
				) : null
			)}
		</TableRow>
	);
}
