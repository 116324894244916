import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso"; // TODO: probably change on react-virtualized
import { VirtualizedTableProps } from "./types";
import {
	VirtuosoTableComponents,
	fixedHeaderContent,
} from "./VirTableComponents";

export default function VirtualizedTable({
	columns,
	rows,
	height = "400px",
}: VirtualizedTableProps) {
	function rowContent(_index: number, row: any) {
		return (
			<>
				{columns.map((column) => (
					<TableCell
						key={column.dataKey}
						align={column.numeric || false ? "right" : "left"}
					>
						{row[column.dataKey]}
					</TableCell>
				))}
			</>
		);
	}

	return (
		<Paper style={{ height: height, width: "100%" }}>
			<TableVirtuoso
				data={rows}
				components={VirtuosoTableComponents}
				fixedHeaderContent={() => fixedHeaderContent(columns)}
				itemContent={rowContent}
			/>
		</Paper>
	);
}
