import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { LibraryItemPartType } from "../../types";
import { Button } from "@mui/material";
import { LIBRARY_ITEM_PART_TYPE } from "../../constants";

export default function LibraryItemFile({ part }: { part: LibraryItemPartType }) {
	const { url, type, name, mimeType } = part;

	const handleOpenPdf = async (e: any) => {
		e.preventDefault();
		const response = await fetch(url);
		const blob = await response.blob();
		const pdfUrl = URL.createObjectURL(blob);
		window.open(pdfUrl, "_blank", "noopener,noreferrer");
	};

	if (type === LIBRARY_ITEM_PART_TYPE.AUDIO) {
		return (
			<a href={url} target="_blank" rel="noopener noreferrer" style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
				<CardMedia component="audio" src={url} controls />
			</a>
		);
	}

	if (type === LIBRARY_ITEM_PART_TYPE.VIDEO) {
		return (
			<a href={url} target="_blank" rel="noopener noreferrer" style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
				<CardMedia component="video" height="300" image={url} />
			</a>
		);
	}

	if (type === LIBRARY_ITEM_PART_TYPE.IMAGE) {
		return (
			<a href={url} target="_blank" rel="noopener noreferrer" style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
				<CardMedia component="img" height="300" image={url} alt={name} />
				<Button size="small" color="info">
					Open full image in the new tab
				</Button>
			</a>
		);
	}

	if (type === LIBRARY_ITEM_PART_TYPE.FILE && mimeType === "application/pdf") {
		return (
			<div style={{ padding: "0 16px" }}>
				<Typography variant="body2" color="text.secondary">
					Preview for this attachment type is not supported. Type: {type}
					<br />
					<a href={url} onClick={handleOpenPdf}>
						<Button size="small" color="info">
							Open PDF
						</Button>
					</a>
				</Typography>
			</div>
		);
	}

	return (
		<div style={{ padding: "0 16px" }}>
			<Typography variant="body2" color="text.secondary">
				Preview for this attachment type is not supported, <br /> type: {type}
				<br />
				<a href={url} target="_blank" rel="noopener noreferrer">
					<Button size="small" color="info">
						Download file
					</Button>
				</a>
			</Typography>
		</div>
	);
}
