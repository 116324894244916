import { LibraryType } from "../../types";
import LibraryItem from "./LibraryItem";

interface SuggestionPropType {
	item: LibraryType;
	onApprove?: (id: string) => void;
	onReject?: (id: string) => void;
}

export function SuggestionItem({ item, ...props }: SuggestionPropType) {
	const libraryItem = {
		...item.libraryItem,
		idSuggestion: item.id,
		suggestionCreated: item.created,
	};

	return <LibraryItem item={libraryItem} {...props} />;
}
